/* eslint-disable no-undef */
const iplookup = 'https://ipwhois.pro?key=nm5zgxuueZPJ9yHs'

import { customerZones, currencyList, languageCodeArray } from "../constants/localization";

const getLocale = async () => {

    let display_local = {}
    let locale = {}

    if (localStorage.getItem("user-prefsv2") === null) {
        let response
        try {
            response = await fetch(iplookup)
            if(response.status!==200){
                window.dispatchEvent(new Event("locate_error"))
            }
        }catch{
            window.dispatchEvent(new Event("locate_error"))
            return
        }
        
        const myJson = await response.json();

        locale.calling_code = myJson.calling_code
        locale.region = myJson.region

        locale.location = {}
        locale.location.continent = myJson.continent
        locale.location.continent_code = myJson.continent_code
        locale.location.country = myJson.country
        locale.location.country_code = myJson.country_code

        locale.currency = {}
        locale.currency.code = myJson.currency.code
        locale.currency.name = myJson.currency.name
        locale.currency.symbol = myJson.currency.symbol

        locale.flag = {}
        locale.flag.img=myJson.flag.img
        locale.flag.emoji=myJson.flag.emoji
        locale.flag.emoji_unicode=myJson.flag.emoji_unicode

        locale.timezone = {}
        locale.timezone.id=myJson.timezone.id
        locale.timezone.abbr=myJson.timezone.abbr

        localStorage.setItem('user-localev2', JSON.stringify(locale));

        //Set currencies
        display_local.currency={}
        let currency = locale.currency.code
        if(currencyList[currency]===undefined)
            currency="USD"
        display_local.currency = currencyList[currency];

        //Set customer zone
        let zone
        let country = locale.location.country_code
        if(customerZones.UK.includes(country)){
            zone='UK'
        }else if(customerZones.EU.includes(country)){
            zone = 'EU'
        }else if(customerZones.US.includes(country)){
            zone = 'US'
        }else{
            zone = 'other'
        }
        display_local.customer_zone=zone

        //Set display language
        let lower_case_lang = navigator.language.toLowerCase().substring(0, 2)
        if(languageCodeArray.includes(lower_case_lang)){
            display_local.language=lower_case_lang
        }else{
            display_local.language="en"
        }

        localStorage.setItem('user-prefsv2', JSON.stringify(display_local));
        await localStorage.setItem('i18nextLng', display_local.language);
        window.dispatchEvent(new Event("located"));
    }/*else{
        console.log("getLocale Cached")
    }*/
  }

export default getLocale
