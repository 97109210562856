import React from "react";
import { useTranslation } from "react-i18next";
import DeleteModal from "./DeleteModal";
import { handleDeleteAccount } from "../../../../utils/api";
import { useMutation } from "react-query";
import { AuthContext } from "../../../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

const DeleteUser = ({ setLoggedIn, userDetails }) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(null);
  const [show, setShow] = React.useState(false);

  const { refreshLogin } = React.useContext(AuthContext);

  const deleteAccount = useMutation({
    mutationFn: handleDeleteAccount,
    onSuccess: () => {
      // setDeleteResponse("success");
      setTimeout(() => {
        localStorage.removeItem("safetag-ref_token");
        localStorage.removeItem("safetag-id_token");
        localStorage.removeItem("safetag-username");
        setLoggedIn(false);
        history("/login");
      }, 2000);
    },
    onError: async (e) => {
      // setDeleteText(t("account.buttons.delete"));
      const error_text = e.response.data.message;
      if (error_text === "User does not exist.") {
        setError(t("account.errors.not_found"));
      } else if (error_text === "Incorrect username or password.") {
        setError(t("account.errors.incorrect"));
      } else if (error_text === "Unauthorized") {
        setError(t("account.errors.unauthorized"));
      } else if (
        e?.response?.data?.message === "jwt expired" ||
        e?.response?.data?.message === "jwt malformed" ||
        e?.response?.data?.message === "invalid token"
      ) {
        await refreshLogin();
        deleteAccount.mutate({ username, password });
      } else {
        setError(t("account.errors.general"));
      }
    },
  });

  return (
    <div
      className={"mt-4 w-full bg-gray-100 p-4 sm:p-8 flex flex-col sm:items-start gap-4 " + (!show && "cursor-pointer")}
      onClick={() => setShow(true)}
    >
      <DeleteModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        mutation={deleteAccount}
        error={error}
        setError={setError}
        credentials={{
          username,
          password,
        }}
      />
      <div className="flex flex-row items-center justify-between w-full">
        <p>{t("account.delete.title")}</p>

        {show ? (
          <BsChevronUp
            onClick={(e) => {
              e.stopPropagation();
              setShow(false);
            }}
            className="cursor-pointer"
          />
        ) : (
          <BsChevronDown />
        )}
      </div>

      <div
        className="flex flex-col gap-4 sm:items-start transition-all duration-500 overflow-hidden px-px"
        style={{
          height: show ? 220 : 0,
        }}
      >
        <div className="flex flex-row items-start md:items-center gap-2 px-2 py-1 rounded bg-pr bg-opacity-5 w-fit border border-sr border-opacity-20">
          <img
            src="/assets/history_management/icons/warning.svg"
            alt="warning"
            className="my-1"
          />
          <p className="text-sm font-medium text-pr ">
            {t("history_management.delete.irreversible")}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full sm:w-80 h-12 bg-transparent border border-gray-300 rounded-md text-gray-900"
            type="email"
            placeholder="Email"
            name=""
            id=""
          />
        </div>
        <div className="flex flex-col gap-2">
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full sm:w-80 h-12 bg-transparent border border-gray-300 rounded-md text-gray-900"
            type="password"
            placeholder="Password"
            name=""
            id=""
          />
        </div>

        <button
          onClick={() => setShowModal(true)}
          disabled={username === "" || password === ""}
          className="px-4 py-3 bg-pr transition-all duration-300 text-white rounded-md disabled:opacity-40"
        >
          {t("account.delete.title")}
        </button>
      </div>
    </div>
  );
};

export default DeleteUser;
