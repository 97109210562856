import React from "react";
import { useMutation } from "react-query";
import { handleCreatePortal } from "../../../utils/api";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

const Billing = () => {
  const { userDetails } = useOutletContext();
  const { t } = useTranslation();
  const portal = useMutation({
    mutationFn: handleCreatePortal,
    onSuccess: (data) => {
      window.location.href = data.url;
    },
  });
  return (
    <div className="w-full border rounded-lg flex flex-col mt-6 gap-6 p-4 sm:p-8">
      <p>{t("account.billing.message")}</p>

      <button
        onClick={() =>
          portal.mutate({ userEmail: userDetails?.data?.username })
        }
        disabled={portal.isLoading || portal.isSuccess}
        className="w-full sm:w-fit px-4 py-3 bg-pr hover:bg-sr transition-all duration-300 text-white rounded-md disabled:opacity-40"
      >
        {portal.isLoading || portal.isSuccess
          ? t("account.buttons.redirecting")
          : t("account.buttons.billing")}
      </button>
    </div>
  );
};

export default Billing;
