import React from "react";
import { useTranslation } from "react-i18next";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import ProductCard from "../ProductCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

const ShopSection = ({ products }) => {
  const { t } = useTranslation();
  const [swiperRef, setSwiperRef] = React.useState({});
  const [activeIndex, setActiveIndex] = React.useState(0);
  return (
    <section className="w-full px-4 py-20 flex flex-col items-center bg-pr relative overflow-hidden">
      <h2 className="text-3xl md:text-4xl font-bold text-white mb-8 hidden lg:flex">
        {t("navigation.main.shop_trackers")}
      </h2>
      <h2 className="text-3xl md:text-4xl font-bold text-white mb-8 lg:hidden">
        {t("navigation.main.shop_trackers")}
      </h2>
      {products.isSuccess && (
        <div className="flex flex-col items-center gap-8 w-full">
          <div className="hidden md:flex flex-col items-center w-full">
            <div className="flex flex-row gap-3 items-center w-full">
              {products.data.length > 3 && (
                <button
                  onClick={() => swiperRef.slidePrev()}
                  disabled={swiperRef.activeIndex === 0}
                  className={`group bg-white disabled:bg-opacity-30 transition-all p-2 rounded-full hidden lg:block`}
                >
                  <BsArrowLeftShort className={`text-pr text-2xl`} />
                </button>
              )}
              <Swiper
                slidesPerView={1.8}
                spaceBetween={20}
                className="w-full lg:max-w-6xl"
                onInit={(e) => setSwiperRef(e)}
                onSlideChange={(e) => setActiveIndex(e.activeIndex)}
                centerInsufficientSlides={true}
                breakpoints={{
                  768: { slidesPerView: 1.8 },
                  1024: { slidesPerView: 3 },
                }}
              >
                {products.data.map((product, index) => (
                  <SwiperSlide key={index} style={{ height: "auto" }}>
                    <ProductCard product={product} />
                  </SwiperSlide>
                ))}
              </Swiper>
              {products.data.length > 3 && (
                <button
                  onClick={() =>
                    activeIndex === products.data.length - 2
                      ? swiperRef.slideTo(products.data.length - 1)
                      : swiperRef.slideNext()
                  }
                  disabled={swiperRef.activeIndex === products.data.length - 3}
                  className={`group bg-white disabled:bg-opacity-30 transition-all p-2 rounded-full hidden lg:block`}
                >
                  <BsArrowRightShort className={`text-pr text-2xl`} />
                </button>
              )}
            </div>

            {products.data.length > 3 && (
              <div className="w-[70%] hidden lg:flex flex-row h-3 mt-8 bg-[#A36465] rounded-full overflow-hidden relative">
                <div
                  className={`bg-white rounded-full transition-all absolute left-0 top-0 h-full z-[1]`}
                  style={{
                    width: `${100 / (products.data.length - 2)}%`,
                    transform: `translateX(${100 * activeIndex}%)`,
                  }}
                ></div>
                {Array.from({ length: products.data.length - 2 }, (i) => i).map(
                  (a, i) => (
                    <div
                      key={i}
                      className="flex-1 relative z-[2] cursor-pointer"
                      onClick={() => swiperRef.slideTo(i)}
                    ></div>
                  )
                )}
              </div>
            )}

            <div className="hidden md:flex lg:hidden flex-row gap-2 items-center justify-end mt-8 w-full px-4 max-w-6xl">
              <button
                onClick={() => swiperRef.slidePrev()}
                disabled={swiperRef.activeIndex === 0}
                className={`group bg-white disabled:bg-opacity-30 transition-all p-2 rounded-full`}
              >
                <BsArrowLeftShort className={`text-pr text-2xl`} />
              </button>
              <button
                onClick={() =>
                  swiperRef.activeIndex < products.data.length - 3
                    ? swiperRef.slideNext()
                    : swiperRef.slideTo(products.data.length - 1)
                }
                disabled={swiperRef.activeIndex === products.data.length - 2}
                className={`group bg-white disabled:bg-opacity-30 transition-all p-2 rounded-full`}
              >
                <BsArrowRightShort className={`text-pr text-2xl`} />
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-4 md:hidden">
            {products.data.slice(0, 3).map((product, index) => (
              <ProductCard key={index} product={product} />
            ))}
          </div>

          <Link
            to="/shop"
            className="bg-white px-6 py-3 rounded-lg hover:bg-gray-300 transition-all duration-300 font-semibold w-full text-center md:w-fit lg:hidden"
          >
            {t("home.products.shop_button")}
          </Link>
        </div>
      )}
    </section>
  );
};

export default ShopSection;
