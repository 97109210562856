import React from "react";
import { useTranslation } from "react-i18next";
import Stars from "../Stars";

const ReviewsSection = ({ products }) => {
  const { t } = useTranslation();
  const [reviews, setReviews] = React.useState([]);
  const [language, setLanguage] = React.useState("en");

  React.useEffect(() => {
    if (products.isSuccess) {
      const allReviews = products.data
        .reduce((a, b) => {
          if (b.display.reviews[language])
            return [...a, ...b.display.reviews[language]];
          else return a;
        }, [])
        .sort(() => Math.random() - 0.5);
      setReviews(allReviews.slice(0, 2));
    }
  }, [products]);

  React.useEffect(() => {
    if (localStorage.getItem("user-prefsv2") != null) {
      let user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
      setLanguage(user_prefs.language);
    }
  }, []);
  

  return (
    <section className="w-full px-4 py-20 flex flex-col items-center bg-pr relative overflow-hidden">
      <img
        src="/assets/wireless/bg.svg"
        className="absolute z-0 w-full h-full top-0 left-0"
      />

      <div className="flex flex-col gap-16 w-full max-w-6xl relative z-[2]">
        <h2 className="text-white text-3xl font-bold text-center md:text-left w-full">
          {t("wireless.reviews.title")}
        </h2>
        <div className="flex flex-col md:flex-row gap-6">
          {reviews.map((review, index) => (
            <ReviewCard review={review} key={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

const ReviewCard = ({ review }) => {
  return (
    <div className="md:flex-1 flex flex-col rounded-2xl bg-white border p-8 gap-4">
      <div className="flex flex-col items-start lg:flex-row lg:items-center w-full justify-between gap-2">
        <div className="flex flex-row items-center gap-1">
          <b className="hidden md:block text-lg">{review.stars}/5</b>
          <Stars stars={review.stars} />
        </div>
        <div className="flex flex-row gap-1">
          <p className="font-semibold">{review.reviewer}</p>
        </div>
      </div>
      <h3 className="text-lg text-pr font-bold">{review.title}</h3>
      <p className="text-opacity-70 ">{review.body}</p>
    </div>
  );
};

export default ReviewsSection;
