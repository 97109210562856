import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import TableSection from "./TableSection";
import HeroSection from "./HeroSection";
import ReviewsSection from "../Shared/Wired&Wireless/ReviewsSection";
import FeaturesSection from "./FeaturesSection";
import ShopSection from "../Shared/Wired&Wireless/ShopSection";
import FeaturedProducts from "../Shared/Wired&Wireless/FeaturedProducts";
import StaticSection from "./StaticSection";
import AppSection from "../Shared/AppSection";
import Footer from "../Shared/Footer";
import { useQuery } from "react-query";
import { handleFetchProducts } from "../../utils/api";

const AssetTrackers = () => {
  const { t } = useTranslation();
  const products = useQuery({
    queryKey: ["products", { category: "asset" }],
    queryFn: handleFetchProducts,
    refetchOnWindowFocus: false,
    refetchOnTabFocus: false,
  });

  return (
    <div className="w-full min-h-screen flex flex-col">
      <Helmet>
        <title>{t("asset.main.title")}</title>
        <meta name="description" content={t("asset.main.desc1")} />
      </Helmet>

      <main className="flex-1">
        <HeroSection />
        <ReviewsSection products={products} />
        <FeaturesSection />
        <ShopSection products={products} />
        {/* <TableSection /> */}
        <FeaturedProducts
          products={products}
          title={t("wireless.featured.title")}
        />
        <StaticSection />
        <AppSection />
      </main>
      <Footer />
    </div>
  );
};

export default AssetTrackers;
