export const currencyList = {
  GBP: { name: "British Pound", code: "GBP", symbol: "£" },
  EUR: { name: "Euro", code: "EUR", symbol: "€" },
  USD: { name: "US Dollar", code: "USD", symbol: "$" },
  CAD: { name: "Canadian Dollar", code: "CAD", symbol: "C$" },
};

export const currencyArray = [
  { name: "British Pound", code: "GBP", symbol: "£" },
  { name: "Euro", code: "EUR", symbol: "€" },
  { name: "US Dollar", code: "USD", symbol: "$" },
  { name: "Canadian Dollar", code: "CAD", symbol: "C$" },
];

export const languageList = {
  en: "English",
  de: "German",
  fr: "French",
  es: "Spanish",
  it: "Italian",
};

export const languageArray = [
  { name: "English", code: "en" },
  { name: "Française", code: "fr" },
  { name: "Deutsche", code: "de" },
  { name: "Espanol", code: "es" },
  { name: "Italian", code: "it" },
];

export const languageCodeArray = ["en", "fr", "de", "es", "it"];

export const customerZones = {
  UK: ["GB", "GG", "IM", "JE"],
  EU: [
    "BE",
    "BG",
    "CZ",
    "CH",
    "DK",
    "DE",
    "EE",
    "IE",
    "EL",
    "ES",
    "FR",
    "HR",
    "IT",
    "CY",
    "LV",
    "LT",
    "LU",
    "HU",
    "MT",
    "NL",
    "AT",
    "PL",
    "PT",
    "RO",
    "SI",
    "SK",
    "FI",
    "SE",
  ],
  US: [
    "US",
    "CA",
    "MX",
    "BS",
    "PR",
    "HT",
    "DO",
    "JM",
    "CU",
    "BS",
    "BZ",
    "GT",
    "HN",
    "SV",
    "CR",
    "NI",
    "PA",
  ],
};
