import axios from "axios";
import { currencyList, customerZones } from "../constants/localization";
import countryToCurrency from "country-to-currency";
import momentTz from "moment-timezone";
import { dateFormater, sleep } from "./time";
import { v4 } from "uuid";
import { getBrowserMetadata } from "./browser";
import history_data from "../constants/history_demo_data.json";
import demo_data from "../constants/dashboard_demo_data.json";

const URL = import.meta.env.VITE_PROD_API_URL;
const headers = { "Content-Type": "application/json" };

const getCredentials = () => {
  let username = localStorage.getItem("safetag-username");
  let config = {
    headers: {
      Authorization: localStorage.getItem("safetag-id_token"),
    },
  };

  return { username, config };
};

export const handleLogin = async (formData) => {
  const { password, email } = formData;
  let { data } = await axios.post(
    `${URL}/login`,
    { username: email, password: password },
    { headers }
  );
  return data;
};

export const handleResendCode = async ({ username }) => {
  let { data } = await axios.post(`${URL}/users/resend/${username}`, {
    headers,
  });
  return data;
};

export const handleRegister = async ({
  name,
  email,
  password,
  country,
  checked,
  personal,
  referred_by,
}) => {
  let zone;
  if (customerZones.UK.includes(country)) {
    zone = "UK";
  } else if (customerZones.EU.includes(country)) {
    zone = "EU";
  } else if (customerZones.US.includes(country)) {
    zone = "US";
  } else {
    zone = "other";
  }

  //Get currency from country dropdown
  let currency = countryToCurrency[country];
  if (currencyList[currency] === undefined) currency = "USD";

  //Update Local Storage with user selected values
  let user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
  user_prefs.currency = currencyList[currency];
  user_prefs.customer_zone = zone;
  localStorage.setItem("user-prefsv2", JSON.stringify(user_prefs));

  let intl = Intl.DateTimeFormat().resolvedOptions();
  let timezone = JSON.parse(localStorage.getItem("user-localev2"))
    ? JSON.parse(localStorage.getItem("user-localev2")).timezone.id
    : intl.timeZone;
  let language = user_prefs ? user_prefs.language : "en";
  let customer_zone = user_prefs ? user_prefs.customer_zone : "UK";
  let { data } = await axios.post(
    `${URL}/users`,
    {
      name: name,
      username: email,
      password: password,
      country: country,
      contact: checked,
    },
    {
      headers,
      params: {
        timezone,
        language,
        locale: intl.locale,
        customer_zone,
        personal_use: personal,
        referred_by,
      },
    }
  );

  return data;
};

export const handleConfirmEmail = async ({ username, confirmCode }) => {
  let { data } = await axios.post(
    `${URL}/users/confirm/${username}/${confirmCode}`,
    { headers }
  );
  return data;
};

export const handleDeleteHistory = async ({ device }) => {
  const { data } = await axios.delete(
    `${URL}/device/locations/${localStorage.getItem(
      "safetag-username"
    )}/${device}`,
    { headers: { Authorization: localStorage.getItem("safetag-id_token") } }
  );

  return data;
};

export const handleUpdateUser = async ({ params }) => {
  const config = {
    method: "patch",
    url: `${import.meta.env.VITE_PROD_API_URL}/users/${localStorage.getItem(
      "safetag-username"
    )}${params}`,
    headers: {
      Authorization: localStorage.getItem("safetag-id_token"),
      ...headers,
    },
  };
  const { data } = await axios(config);
  return data;
};

export const handleGetUserDetails = async () => {
  const { data } = await axios.get(
    `${import.meta.env.VITE_PROD_API_URL}/users/${localStorage.getItem(
      "safetag-username"
    )}`,
    { headers: { Authorization: localStorage.getItem("safetag-id_token") } }
  );
  return data;
};

export const handleUpdateEmailReports = async ({
  report_recipients,
  report_preferences,
}) => {
  const { data } = await axios.patch(
    `${import.meta.env.VITE_PROD_API_URL}/users/${localStorage.getItem(
      "safetag-username"
    )}`,
    {},
    {
      headers: { Authorization: localStorage.getItem("safetag-id_token") },
      params: {
        report_recipients,
        report_preferences,
      },
    }
  );
  return data;
};

export const handleCreatePortal = async ({ userEmail }) => {
  const { data } = await axios.get(
    URL + "/create-portal-session/" + userEmail + "/account",
    {
      headers: {
        Authorization: localStorage.getItem("safetag-id_token"),
      },
    }
  );

  return data;
};

export const handleDeleteAccount = async ({ username, password }) => {
  var data = JSON.stringify({
    username,
    password,
  });

  var config = {
    method: "delete",
    url: URL + `/users`,
    headers: {
      Authorization: localStorage.getItem("safetag-id_token"),
      ...headers,
    },
    data: data,
  };

  const res = await axios(config);
  return res.data;
};

export const handleDownloadData = async (startTime, endTime, device) => {
  const { data } = await axios.get(
    `${URL}/device/locations/range/${localStorage.getItem(
      "safetag-username"
    )}/${device}?start=${dateFormater(
      startTime,
      "YYYY-MM-DD"
    )}&end=${dateFormater(endTime, "YYYY-MM-DD")}&tz=${momentTz.tz.guess()}`,
    { headers: { Authorization: localStorage.getItem("safetag-id_token") } }
  );
  return data;
};

export const handleGetDevices = async () => {
  const { data } = await axios.get(
    URL + "/device/" + localStorage.getItem("safetag-username"),
    { headers: { Authorization: localStorage.getItem("safetag-id_token") } }
  );
  const customError = {
    response: {
      status: 500,
      data: {
        message: "invalid token",
      },
    },
  };

  //Stress Test

  // Concatenate updatedData onto itself 20 times
  /*
  const concatenatedData = Array(200).fill(data).flat();
  let filteredData = concatenatedData.filter((tracker) => tracker.location !== "");
  // Generate random string for each tracker's prefs.name
  const randomString = () => Math.random().toString(36).substring(7);
  const getRandomCoordinate = () => {
    const minLatitude = 49.823809;
    const maxLatitude = 58.635000;
    const minLongitude = -6.417000;
    const maxLongitude = 1.768189;
    const latitude = Math.random() * (maxLatitude - minLatitude) + minLatitude;
    const longitude = Math.random() * (maxLongitude - minLongitude) + minLongitude;
    return `${latitude},${longitude}`;
  };
  const updatedData = filteredData.map((tracker) => ({
    ...tracker,
    prefs: {
      ...tracker.prefs,
      name: randomString(),
    },
    status: {
      ...tracker.status,
      location: getRandomCoordinate(),
    },
  }));
  return updatedData;
  */

  // throw customError;
  return data;
};

export const handleAddDevice = async ({ tracker_id, tracker_name }) => {
  var config = {
    method: "patch",
    url: `${URL}/device/owner/${localStorage.getItem("safetag-username")}`,
    headers: {
      Authorization: localStorage.getItem("safetag-id_token"),
      ...headers,
    },
    data: JSON.stringify({
      imei: tracker_id,
      name: tracker_name,
    }),
  };

  const { data } = await axios(config);
  return data;
};

export const handleSubmitContract = async ({ data, active_contract }) => {
  var config = {
    method: "patch",
    url: `${URL}/device/business-activation/${localStorage.getItem(
      "safetag-username"
    )}/${active_contract.id}`,
    headers: {
      Authorization: localStorage.getItem("safetag-id_token"),
      ...headers,
    },
    data: data,
  };

  const res = await axios(config);
  return res?.data;
};

export const handleLookupTracker = async ({ id }) => {
  var config = {
    method: "get",
    url: `${URL}/device/lookups/${localStorage.getItem(
      "safetag-username"
    )}?imei=${id}`,
    headers: {
      Authorization: localStorage.getItem("safetag-id_token"),
      ...headers,
    },
  };
  const { data } = await axios(config);
  return data;
};

export const handleContact = async ({ data }) => {
  let response = await axios.post(`${URL}/contact`, data);
  return response.data;
};

export const handleFetchProducts = async ({ queryKey }) => {
  let ls_zone;
  if (localStorage.getItem("user-prefsv2") != null) {
    let user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
    ls_zone = user_prefs.customer_zone;
  }
  const { data } = await (ls_zone
    ? axios.get(`${URL}/products?customer_zone=${ls_zone}`, {
        params: queryKey[1],
      })
    : axios.get(URL + "/products", { params: queryKey[1] }));

  return data;
};

export const handleFetchProduct = async (props) => {
  const { data } = await axios.get(URL + "/product/" + props.queryKey[1]);
  return data;
};

export const handleFetchPricing = async (props) => {
  let ls_zone;
  if (localStorage.getItem("user-prefsv2") != null) {
    let user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
    ls_zone = user_prefs.customer_zone;
  }
  const { data } = await axios.get(
    `${import.meta.env.VITE_PROD_API_URL}/zones/pricing/${
      props.queryKey[1]
    }/${ls_zone}`
  );

  return data;
};

export const handleFetchZone = async ({ id }) => {
  const { data } = await axios.get(`${URL}/zone/${id}`);
  return data;
};

export const handleFetchZones = async (all = false) => {
  let ls_zone;
  if (localStorage.getItem("user-prefsv2") != null) {
    let user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
    ls_zone = user_prefs.customer_zone;
  }

  const { data } = await (ls_zone
    ? axios.get(URL + "/zones?customer_zone=" + ls_zone)
    : axios.get(URL + "/zones"));
  await sleep(0);
  return data;
};

export const handleFetchAllZones = async (all = false) => {
  const { data } = await axios.get(URL + "/zones");
  return data;
};

export const handleUpdateSubscription = async () => {
  const { data } = await axios.get(
    `${URL}/create-portal-session/${localStorage.getItem(
      "safetag-username"
    )}/account?flow=payment_method_update`,
    {
      headers: {
        Authorization: localStorage.getItem("safetag-id_token"),
      },
    }
  );
  return { data };
};

export const handleUpdateDeviceName = async ({ deviceName, devEditId }) => {
  let username = localStorage.getItem("safetag-username");
  let payload = {
    name: deviceName,
    device_id: devEditId,
  };
  payload = JSON.stringify(payload);
  const { data } = await axios.patch(
    `${URL}/device/name/${username}`,
    payload,
    {
      headers: {
        Authorization: localStorage.getItem("safetag-id_token"),
        "Content-type": "application/json",
      },
    }
  );
  return data;
};

export const handleRemoveDevice = async ({ devRemovalId }) => {
  let username = localStorage.getItem("safetag-username");
  const { data } = await axios.patch(
    import.meta.env.VITE_PROD_API_URL +
      "/device/remove/" +
      username +
      "?device_id=" +
      devRemovalId,
    {},
    {
      headers: {
        Authorization: localStorage.getItem("safetag-id_token"),
      },
    }
  );
  return data;
};

export const handleCancelSubscription = async ({
  devCancelId,
  reason,
  feedback,
}) => {
  let username = localStorage.getItem("safetag-username");
  const { data } = await axios.delete(
    `${URL}/device/subscription/${username}`,
    {
      headers: {
        Authorization: localStorage.getItem("safetag-id_token"),
      },
      params: {
        device_id: devCancelId,
        reason,
        feedback,
      },
    }
  );

  return data;
};

export const handleOpenSubscriptionInvoice = async ({ device_id }) => {
  let username = localStorage.getItem("safetag-username");
  let { data } = await axios.get(
    URL + "/device/invoice/subscription_latest/" + username + "/" + device_id,
    {
      headers: {
        Authorization: localStorage.getItem("safetag-id_token"),
      },
    }
  );
  return data;
};

export const handleFetchDeviceHistory = async (props) => {
  let username = localStorage.getItem("safetag-username");
  const { data } = await axios.get(
    import.meta.env.VITE_PROD_API_URL +
      `/device/journey/date/${username}?device_id=${
        props.queryKey[1]
      }&date=${dateFormater(
        props.queryKey[2],
        "YYYY-MM-DD"
      )}&tz=${momentTz.tz.guess()}&platform=web`,
    {
      headers: {
        Authorization: localStorage.getItem("safetag-id_token"),
      },
    }
  );

  // TESTING

  // const result = {
  //   "865080043483979":history_data[0],
  //   "865080043481106":history_data[1],
  //   "865080043480900": history_data[2],,

  // }

  // return result[props.queryKey[1]];

  // return history_data[2];
  return data;
};

export const handleEnableNotifications = async ({ token }) => {
  let username = localStorage.getItem("safetag-username");
  let device_id;
  if (localStorage.getItem("fcm_device_id")) {
    device_id = localStorage.getItem("fcm_device_id");
  } else {
    device_id = v4();
    localStorage.setItem("fcm_device_id", device_id);
  }

  let install = getBrowserMetadata();
  let { data } = await axios.post(
    `${URL}/users/fcm_id/${username}`,
    {
      token,
      device_id,
      install,
    },
    {
      headers: {
        Authorization: localStorage.getItem("safetag-id_token"),
        "Content-Type": "application/json",
      },
    }
  );

  return { ...data, token };
};

export const handleDisableNotifications = async () => {
  let username = localStorage.getItem("safetag-username");
  const { data } = await axios.delete(`${URL}/users/fcm_id/${username}`, {
    data: {
      token: localStorage.getItem("fcm_token"),
    },
    headers: {
      Authorization: localStorage.getItem("safetag-id_token"),
      "Content-Type": "application/json",
    },
  });
  return data;
};

export const handleResetPassword = async ({
  newPass,
  username,
  confirmCode,
}) => {
  const { data } = await axios.post(
    `${URL}/users/reset/${username}/${confirmCode}`,
    { password: newPass },
    { headers: { ...headers } }
  );
  return data;
};

export const handleSendResetCode = async ({ username }) => {
  // TEST
  // throw {
  //   response: {
  //     data: {
  //       message:
  //         "Cannot reset password for the user as there is no registered/verified email or phone_number",
  //     },
  //   },
  // };
  const { data } = await axios.post(
    URL + "/users/reset/" + username,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

export const getZonePricing = async (device) => {
  const username = localStorage.getItem("safetag-username");
  var config = {
    method: "get",
    url: URL + "/device/zones/pricing/" + username + "/" + device._id,
    headers: {
      Authorization: localStorage.getItem("safetag-id_token"),
      "Content-Type": "application/json",
    },
  };
  const { data } = await axios(config);
  return data;
};

export const handleUpdateDevice = async ({
  device,
  subVal,
  selectedZoneId,
  refresh,
}) => {
  const username = localStorage.getItem("safetag-username");
  var url = `${URL}/device/subscription/${username}/${device._id}?cycle=${subVal}&coverage_zone=${selectedZoneId}`;
  if (refresh) url += `&refresh=${refresh}`;
  var config = {
    method: "patch",
    url,
    headers: {
      Authorization: localStorage.getItem("safetag-id_token"),
      "Content-Type": "application/json",
    },
  };
  const { data } = await axios(config);
  return data;
};

export const getDeviceAlerts = async (props) => {
  let username = localStorage.getItem("safetag-username");
  const { data } = await axios.get(
    `${URL}/device/alerts/${username}/${props.queryKey[1]}`,
    {
      headers: {
        Authorization: localStorage.getItem("safetag-id_token"),
      },
    }
  );
  return data;
};

export const getTrackerAddress = async ({ deviceId, location_id }) => {
  if (deviceId === "demo") {
    return { data: "demo" };
  }
  let username = localStorage.getItem("safetag-username");
  let { data } = await axios.get(
    import.meta.env.VITE_PROD_API_URL +
      "/device/address/" +
      username +
      "/" +
      deviceId,
    {
      headers: {
        Authorization: localStorage.getItem("safetag-id_token"),
        "Content-Type": "application/json",
      },
      params: { location_id },
    }
  );
  if (data === "No positioning data found.") {
    throw { message: "No positioning data found." };
  } else {
    return data;
  }
};

// Tracker Settings

export const changeTrackerName = async ({ device_id, name }) => {
  let { username, config } = getCredentials();
  const { data } = await axios.patch(
    `${URL}/device/name/${username}`,
    { name, device_id },
    config
  );

  return data;
};

export const changeTrackerGeoFence = async ({
  device_id,
  geofence_centre,
  geofence_size,
}) => {
  let { username, config } = getCredentials();
  const { data } = await axios.patch(
    `${URL}/device/geofence/${username}`,
    { device_id, geofence_centre, geofence_size },
    config
  );

  return data;
};

export const changeTrackerSpeedLimit = async ({ device_id, limit }) => {
  let { username, config } = getCredentials();
  const { data } = await axios.patch(
    `${URL}/device/speed_limit/${username}`,
    { device_id, limit },
    config
  );

  return data;
};

export const changeTrackerCategory = async ({
  device_id,
  name,
  selectedIcon,
}) => {
  let { username, config } = getCredentials();
  const { data } = await axios.patch(
    `${URL}/device/category/${username}?device_id=${device_id}&category=${selectedIcon}`,
    { device_id, name },
    config
  );

  return data;
};

export const changeTrackerMarker = async ({
  device_id,
  name,
  selectedMarker,
}) => {
  let { username, config } = getCredentials();
  const { data } = await axios.patch(
    `${URL}/device/marker/${username}?device_id=${device_id}&marker=${selectedMarker}`,
    { device_id, name },
    config
  );
  return data;
};

export const changeTrackerAlerts = async ({ device_id, alerts }) => {
  let { username, config } = getCredentials();
  const { data } = await axios.patch(
    `${URL}/device/alerts/${username}`,
    { device_id, alerts },
    config
  );

  return data;
};

export const verifyToken = async ({ token }) => {
  const { data } = await axios.post(
    import.meta.env.VITE_PROD_API_URL + "/verify-token",
    { reCAPTCHA_TOKEN: token }
  );
  return data;
};

export const fetchGeofences = async () => {
  let { username, config } = getCredentials();
  const { data } = await axios.get(`${URL}/geofences/${username}`, config);
  return data;
};

export const deleteGeofences = async ({ params }) => {
  let { username, config } = getCredentials();
  const { data } = await axios.delete(`${URL}/geofences/${username}`, {
    ...config,
    params,
  });
  return data;
};

export const updateDeviceGeofences = async ({ add, remove, deviceId }) => {
  let { username, config } = getCredentials();
  await axios.patch(
    `${URL}/device/geofences/${username}`,
    {
      add,
      remove,
      devices: [deviceId],
    },
    config
  );
};

export const createNewGeofence = async ({ params }) => {
  let { username, config } = getCredentials();
  const { data } = await axios.post(
    `${URL}/geofences/${username}`,
    params,
    config
  );
  return data;
};

export const updateGeofence = async ({ id, params }) => {
  let { username, config } = getCredentials();
  const { data } = await axios.patch(`${URL}/geofences/${username}`, params, {
    ...config,
    params: {
      ids: id,
    },
  });
  return data;
};

export const removeFCMToken = async () => {
  const username = localStorage.getItem("safetag-username");
  const { data } = await axios.delete(URL + "/users/fcm_id/" + username, {
    data: { token: localStorage.getItem("fcm_token") },
    headers: {
      Authorization: localStorage.getItem("safetag-id_token"),
      "Content-Type": "application/json",
    },
  });
};

export const handleStripe = async ({
  tracker_id,
  subVal,
  zone,
  currency_code,
  refreshRate,
}) => {
  const { data } = await axios.get(
    URL +
      "/device/subscription/" +
      localStorage.getItem("safetag-username") +
      "/" +
      tracker_id +
      "/" +
      subVal +
      "/add-device?coverage_id=" +
      zone.id +
      "&currency=" +
      currency_code.toLowerCase() +
      "&refresh=" +
      refreshRate,
    {
      headers: {
        Authorization: localStorage.getItem("safetag-id_token"),
      },
    }
  );

  return data;
};

export const getAppToken = async () => {
  const { data } = await axios.get(
    URL + "/users/app_token/" + localStorage.getItem("safetag-username"),
    {
      headers: {
        Authorization: localStorage.getItem("safetag-id_token"),
      },
    }
  );
  return data;
};

export const handleGetTopSpeeds = async ({ queryKey }) => {
  const range = queryKey[1];
  let { username, config } = getCredentials();
  const { data } = await axios.get(`${URL}/stats/top-speeds/${username}`, {
    ...config,
    params: {
      range,
    },
  });

  // return demo_data.topSpeed.data;
  return data;
};

export const handleGetAlerts = async (props) => {
  let username = localStorage.getItem("safetag-username");
  let page = props.pageParam || 1;
  const { data } = await axios.get(
    `${URL}/device/alerts/${username}/${props.queryKey[1]}`,
    // "http://localhost:9900/api/test",
    {
      params: { page },
      headers: {
        Authorization: localStorage.getItem("safetag-id_token"),
      },
    }
  );
  // await sleep(3000);
  return { data, nextCursor: data.length === 40 ? page + 1 : undefined };
};

export const createAppToken = async (expiry, expiryDate) => {
  let params = {};
  if (expiry) {
    params = {
      expiry: expiryDate,
    };
  }
  const { data } = await axios.post(
    URL + "/users/app_token/" + localStorage.getItem("safetag-username"),
    {},
    {
      params,
      headers: {
        Authorization: localStorage.getItem("safetag-id_token"),
      },
    }
  );
  return data;
};

export const deleteAppToken = async (id) => {
  const { data } = await axios.delete(
    URL +
      "/users/app_token/" +
      localStorage.getItem("safetag-username") +
      "/" +
      id,
    {
      headers: {
        Authorization: localStorage.getItem("safetag-id_token"),
      },
    }
  );
  return data;
};

export const updateWebhook = async (url, secret) => {
  const { username, config } = getCredentials();
  const { data } = await axios.patch(
    `${URL}/users/webhook/${username}`,
    { url, secret },
    config
  );
  return data;
};

export const deleteWebhook = async () => {
  const { username, config } = getCredentials();
  const { data } = await axios.delete(
    `${URL}/users/webhook/${username}`,
    config
  );
  return data;
};

export const testWebhook = async () => {
  const { username, config } = getCredentials();
  const { data } = await axios.post(
    `${URL}/users/webhook/${username}/test`,
    config
  );
  return data;
};

export const getDefaultConfig = async (id) => {
  const { data } = await axios.get(`${URL}/product/${id}/config`);
  return data;
};

export const handleRetainSubscription = async ({ device }) => {
  const { data } = await axios.patch(
    `${URL}/device/subscription/coupon/${localStorage.getItem(
      "safetag-username"
    )}/${device}?coupon=first_cancel`,
    {},
    { headers: { Authorization: localStorage.getItem("safetag-id_token") } }
  );

  return data;
};

export const handleGetCredits = async () => {
  const { data } = await axios.get(
    `${URL}/users/stripe/credit/${localStorage.getItem("safetag-username")}`,
    { headers: { Authorization: localStorage.getItem("safetag-id_token") } }
  );

  return data;
};

export const handleInviteReferral = async ({ email }) => {
  const { data } = await axios.post(
    `${URL}/referral/invite/${localStorage.getItem(
      "safetag-username"
    )}/${email}`,
    {},
    { headers: { Authorization: localStorage.getItem("safetag-id_token") } }
  );

  return data;
};
