import React, { useState, useEffect } from "react";
import { useNavigate, NavLink, Link, useLocation } from "react-router-dom";
import { IoIosArrowUp } from "react-icons/io";
import "./css/navigation.css";
import { useTranslation } from "react-i18next";
import { BiChevronRight } from "react-icons/bi";
import { AiFillShopping } from "react-icons/ai";
import { useMutation, useQuery } from "react-query";
import { handleGetUserDetails, removeFCMToken } from "../utils/api";
//PostHog
import { usePostHog } from "posthog-js/react";

// ======= Nav Links SCHEMA
// t_label: translation label for the link text to be passed to the t()
// link: link path
// screen: either show on "mobile" or on "desktop" or "both"
// proteced: only for logged in users
// subLinks: Array of Links in the the dropdown menu if any
// action: in case there is a non-navigation action on the button/link, should be added to the MainNavLink handleAction() switch block

const NAVLINKS = [
  {
    t_label: "navigation.main.home",
    link: "/",
    screen: "both",
  },
  {
    t_label: "navigation.main.shop_trackers",
    link: null,
    screen: "desktop",
    subLinks: [
      {
        t_label: "navigation.main.wired",
        link: "/wired-trackers",
      },
      {
        t_label: "navigation.main.wireless",
        link: "/wireless-trackers",
      },
      {
        t_label: "navigation.main.asset",
        link: "/asset-tracking",
      },
      {
        t_label: "navigation.main.catalogue",
        link: "/shop",
      },
    ],
  },
  {
    t_label: "navigation.main.wired_mobile",
    link: "/wired-trackers",
    screen: "mobile",
  },
  {
    t_label: "navigation.main.wireless_mobile",
    link: "/wireless-trackers",
    screen: "mobile",
  },
  {
    t_label: "navigation.main.catalogue",
    link: "/shop",
    screen: "mobile",
  },
  {
    t_label: "navigation.main.our_app",
    link: "/our-app",
    screen: "both",
    logout: true,
  },
  {
    t_label: "navigation.main.plans",
    link: "/plans",
    screen: "both",
  },
  {
    t_label: "navigation.main.b2b",
    link: "/business",
    screen: "mobile",
    logout: true,
  },
  {
    t_label: "navigation.main.b2b",
    link: "/business",
    screen: "desktop",
    logout: true,
  },
  {
    t_label: "navigation.main.devices",
    link: "/my-devices",
    screen: "mobile",
    protected: true,
  },
  {
    t_label: "navigation.main.add_tracker",
    link: "/add-device",
    screen: "mobile",
    protected: true,
  },
  {
    t_label: "navigation.main.tracking",
    link: null,
    protected: true,
    screen: "desktop",
    subLinks: [
      {
        t_label: "navigation.main.live",
        link: "/track",
      },
      {
        t_label: "navigation.main.history",
        link: "/history",
      },
      {
        t_label: "navigation.main.geofences",
        link: "/my-geofences",
      },
    ],
  },
  {
    t_label: "navigation.main.manage",
    link: null,
    protected: true,
    screen: "desktop",
    subLinks: [
      {
        t_label: "navigation.main.devices",
        link: "/my-devices",
      },
      {
        t_label: "navigation.main.add_tracker",
        link: "/add-device",
      },
      {
        t_label: "navigation.main.history_management",
        link: "/history-management",
      },
    ],
  },
  {
    t_label: "navigation.main.support",
    link: "/support",
  },
  {
    t_label: "navigation.main.account",
    link: "/account",
    protected: true,
    sublinksAlignment: "right",
    subLinks: [
      {
        t_label: "navigation.main.logout",
        action: "logout",
      },
    ],
  },
];

const Navbar = ({ setLoggedIn, loggedIn }) => {
  const history = useNavigate();
  const posthog = usePostHog();
  const path = useLocation().pathname;

  const user = useQuery({
    queryKey: ["user-details"],
    queryFn: handleGetUserDetails,
  });
  //Find the index in the NAVLINKS array where t_label = "navigation.main.tracking"
  const trackingIndex = NAVLINKS.findIndex((l) => l.t_label === "navigation.main.tracking");
  if (
    trackingIndex !== -1 &&
    user.data?.purpose === "business" && 
    NAVLINKS[trackingIndex].subLinks?.find((l) => l.link === "/dashboard") === undefined
  ) {
    NAVLINKS[trackingIndex].subLinks?.push({
      t_label: "navigation.main.dashboard",
      link: "/dashboard",
    });
  }

  const { mutateAsync } = useMutation({
    mutationFn: removeFCMToken,
    onError: (error) => {
      console.log(error);
    },
  });

  const handleLogout = async () => {
    await mutateAsync();
    localStorage.removeItem("fcm_token");
    localStorage.removeItem("safetag-ref_token");
    localStorage.removeItem("safetag-id_token");
    localStorage.removeItem("safetag-username");
    sessionStorage.removeItem("safetag-ref_token");
    setLoggedIn(false);
    posthog.reset();
    history("/login");
  };

  const [mactive, setMactive] = useState(false);

  const [logo, setLogo] = useState("/assets/branding/name.webp");

  const { t } = useTranslation();

  useEffect(() => {
    let stored_logo = localStorage.getItem("business_logo");
    if (stored_logo) setLogo(stored_logo);
  }, []);

  useEffect(() => {
    setMactive(false);
  }, [path]);

  return (
    <>
      <div className="bg-white fixed top-0 left-0 w-full h-16 z-[9] bg-opacity-70 backdrop-blur-lg"></div>
      <div className="w-full flex items-center justify-center sticky top-0 z-10">
        <div className="container">
          <div className="flex items-center justify-between py-3  px-4 ">
            <div className="flex items-center justify-start gap-3 lg:justify-start w-full">
              <Link to="/" className="flex items-center gap-1">
                <img
                  className=" object-contain h-7 md:h-9"
                  src="/assets/branding/shield.webp"
                  alt="SafeTag Logo"
                ></img>
                <img
                  className={
                    "object-contain " +
                    (logo === "/assets/branding/name.webp"
                      ? "h-5 md:h-6"
                      : "h-10 md:h-12")
                  }
                  src={logo}
                  alt="SafeTag Name"
                ></img>
              </Link>
            </div>

            <div className="flex flex-row items-center">
              {/* Links */}
              <div
                className={`${
                  mactive ? "top-0" : "top-[-150%]"
                } fixed flex transition-all duration-500 lg:relative h-screen lg:mr-5 lg:h-auto w-full whitespace-nowrap items-start p-4 pt-16 lg:p-0 lg:items-center lg:w-full top-0 lg:top-auto left-0 lg:left-auto bg-white lg:bg-transparent gap-0 lg:gap-3 flex-col lg:flex-row`}
              >
                {/* General Navigation Links */}
                {NAVLINKS.map((link, index) => (
                  <MainNavLink
                    link={link}
                    key={index}
                    isLogged={loggedIn}
                    logout={handleLogout}
                    hideNav={() => setMactive(false)}
                  />
                ))}

                {/* Login Register */}
                {!loggedIn && (
                  <div className="flex flex-row items-center gap-4 font-medium w-full py-2 lg:py-0">
                    <NavLink
                      onClick={() => setMactive(false)}
                      className={({ isActive }) =>
                        isActive
                          ? "px-5 py-2 bg-gray-100 border rounded-md  cursor-pointer flex-1 text-center"
                          : "px-5 py-2 bg-white hover:bg-gray-100 border rounded-md cursor-pointer flex-1 text-center"
                      }
                      to="/login"
                    >
                      {t("navigation.main.login")}
                    </NavLink>
                    <NavLink
                      onClick={() => setMactive(false)}
                      className={({ isActive }) =>
                        isActive
                          ? "text-white  bg-pr px-3 py-2 rounded-md flex-1 text-center"
                          : "text-white  bg-sr hover:bg-pr px-3 py-2 rounded-md flex-1 text-center"
                      }
                      to="/signup"
                    >
                      {t("navigation.main.register")}
                    </NavLink>
                  </div>
                )}

                {/* Logout  */}
                {loggedIn && (
                  <div className="flex lg:hidden flex-row items-center justify-center gap-4 font-medium w-full mt-3">
                    <div
                      className="px-5 py-2 bg-white hover:bg-gray-100 border rounded-md cursor-pointer text-center"
                      onClick={handleLogout}
                    >
                      {t("navigation.main.logout")}
                    </div>
                  </div>
                )}
              </div>

              {/* cart button */}
              <button className="snipcart-checkout relative flex w-18 text-gray-800 hover:text-pr transition-all duration-200 mb-1">
                <AiFillShopping
                  className="h-7 w-7 object-contain "
                  alt="Shopping Cart"
                ></AiFillShopping>
                <span className="absolute top-0 left-0 snipcart-items-count text-white w-full px-auto pt-1.5 text-sm"></span>
              </button>

              {/* menu icon */}
              <div
                className="relative h-5 lg:hidden ml-5 w-[23px]"
                onClick={() => setMactive((s) => !s)}
              >
                <div
                  className={`absolute transition-all duration-500 top-0 bg-pr left-0 origin-right w-[23px] h-0.5 ${
                    mactive ? "-rotate-45" : "rotate-0"
                  }`}
                ></div>
                <div
                  className={`w-[23px] h-0.5 ${
                    mactive ? "opacity-0" : "opacity-100"
                  } absolute transition-all duration-200 top-2 bg-pr left-0`}
                ></div>
                <div
                  className={`absolute transition-all duration-500 top-4 bg-pr left-0 origin-right w-[23px] h-0.5 ${
                    mactive ? "rotate-45" : "rotate-0"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Main Nav Links that appear on the navbar
const MainNavLink = ({ link, isLogged, logout, hideNav }) => {
  const { t } = useTranslation();
  const [showSubLinks, setSubLink] = useState(false);

  // action other than navigation
  const handleAction = (action) => {
    switch (action) {
      case "logout":
        logout();
        break;
      default:
        return;
    }
  };

  // Incase there is no link on the main Heading of drop down we use Div eg. Manage
  const LinkComponent = link.link ? NavLink : Div;

  // Filter out the links specified logged out users and logged in users
  if ((link.protected && !isLogged) || (link.logout && isLogged)) return null;

  return (
    <div
      onClick={hideNav}
      className={`relative w-full ${
        link.screen === "mobile"
          ? "block lg:hidden"
          : link.screen === "desktop"
          ? "hidden lg:block"
          : ""
      }`}
    >
      <LinkComponent
        onMouseOver={() => setSubLink(true)}
        onMouseOut={() => setSubLink(false)}
        style={({ isActive }) => ({
          color: isActive ? "#8B3333" : "#000000",
        })}
        className="hover:pr w-full whitespace-nowrap"
        to={link.link}
      >
        <div className="border-b w-full border-gray-300 flex flex-row gap-2 items-center py-2 px-1 lg:border-b-2 lg:border-transparent lg:hover:border-pr font-semibold hover:text-pr cursor-pointer transition-all">
          <p>{t(link.t_label)}</p>
          {link.subLinks?.length ? (
            <IoIosArrowUp
              className={`${
                showSubLinks ? "rotate-0" : "rotate-180"
              } transition-all duration-500 hidden lg:block`}
            />
          ) : null}
        </div>
      </LinkComponent>
      {link.subLinks ? (
        <div
          className={`py-1 lg:absolute z-5 bg-transparent ${
            link.sublinksAlignment === "right" ? "right-0" : "left-0"
          } hidden lg:block top-[36px] ${
            showSubLinks ? "pointer-events-auto" : "pointer-events-none"
          }`}
          onMouseOver={() => setSubLink(true)}
          onMouseOut={() => setSubLink(false)}
        >
          <div
            className={`${
              showSubLinks ? "opacity-1" : "opacity-0 hidden lg:block"
            } bg-white rounded-md transition-all duration-500 lg:border flex-col w-64 font-medium lg:p-1 gap-2`}
          >
            {link.subLinks?.map((sublink, index) => (
              <SubLink
                sublink={sublink}
                key={index}
                handleAction={handleAction}
                isLast={index === link.subLinks.length - 1}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

// Links that are shown in the dropdown
const SubLink = ({ sublink, handleAction, isLast }) => {
  const { t } = useTranslation();
  const LinkComponent = sublink.link ? Link : Div;

  return (
    <LinkComponent
      to={sublink.link}
      onClick={() => handleAction(sublink.action)}
      className={`${
        isLast ? "border-transparent" : "border-[#bbb]"
      } text-black hover:text-pr px-1 flex flex-row items-center justify-between lg:border-b cursor-pointer py-2`}
    >
      <p>{t(sublink.t_label)}</p>
      <BiChevronRight className="text-pr text-xl" />
    </LinkComponent>
  );
};

const Div = (props) => {
  const { style, children, ...rest } = props;
  return <div {...rest}>{children}</div>;
};

export default Navbar;
