import React from "react";
import ReactDatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters, AiOutlineMail } from "react-icons/ai";
import { BsCheckCircle, BsChevronDown } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

const CreateApiKey = ({
  isOpen,
  onClose,
  mutation,
  expiry,
  setExpiry,
  expiryDate,
  setExpiryDate,
  tomorrow,
}) => {
  const { t } = useTranslation();

  if (!isOpen) return null;
  return (
    <div
      className="fixed top-0 left-0 h-screen w-full bg-gray-800 bg-opacity-50 z-50 flex flex-col justify-center items-center p-4"
      onClick={onClose}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="max-w-[420px] w-full relative rounded-xl shadow-md bg-white max-h-[90vh] flex flex-col p-8"
      >
        <div className="flex flex-row w-full items-center justify-between mb-4">
          <div className="text-lg font-semibold">
            {t("account.api.create_title")}
          </div>

          <IoClose onClick={onClose} className="cursor-pointer" />
        </div>

        <div className="flex flex-row items-center border rounded p-3">
          <p className="flex-1">{t("account.api.expiry_msg")}</p>

          <div
            className={`w-14 h-6 rounded-full ${
              expiry ? "bg-pr" : "bg-[#EEEEF0]"
            } transition-all duration-300 relative cursor-pointer`}
            onClick={() => setExpiry(!expiry)}
          >
            <div
              className={`top-1/2 -translate-y-1/2 right-1 ${
                expiry
                  ? "translate-x-0 bg-white"
                  : "-translate-x-8 bg-[#838383]"
              } transition-all duration-300 h-4 w-4 absolute rounded-full`}
            ></div>
            <div
              className={`top-1/2 -translate-y-1/2 left-3 text-white absolute text-xs transition-all duration-300 ${
                expiry ? "opacity-100" : "opacity-0"
              }`}
            >
              ON
            </div>
            <div
              className={`top-1/2 -translate-y-1/2 right-2 text-[#838383] absolute text-xs transition-all duration-300 ${
                expiry ? "opacity-0" : "opacity-100"
              }`}
            >
              OFF
            </div>
          </div>
        </div>
        {expiry && (
          <ReactDatePicker
            selected={expiryDate}
            onChange={(date) => {
              setExpiryDate(date);
            }}
            customInput={<DateButton />}
            minDate={tomorrow}
            disabledKeyboardNavigation
          />
        )}

        {/* <p className="text-sm text-opacity-70 text-black font-medium">
          {t("account.api.delete_msg")}
        </p> */}

        <div className="flex flex-col-reverse md:flex-row-reverse justify-start gap-2 w-full mx-auto mt-8">
          <button
            disabled={mutation.isLoading}
            className="flex flex-row items-center justify-center gap-2 bg-pr h-12 md:h-9 hover:bg-sr disabled:bg-neutral-500 rounded-md px-4 py-2 transition-all duration-300 text-white"
            onClick={mutation.mutate}
          >
            {mutation.isLoading && (
              <AiOutlineLoading3Quarters className="animate-spin" />
            )}
            {mutation.isLoading
              ? t("account.api.creating_btn")
              : t("account.api.create_btn")}
          </button>

          <button
            onClick={onClose}
            className="h-12 md:h-9 rounded-md py-1.5 px-3 text-pr bg-pr bg-opacity-5 border border-sr border-opacity-20 hover:bg-white transition-all duration-500"
          >
            {t("geofences.button.cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

const DateButton = React.forwardRef(({ value, onClick, disabled }, ref) => (
  <button
    className={`flex flex-row gap-2 items-center justify-between p-3 border rounded mt-3 w-full`}
    onClick={onClick}
    ref={ref}
  >
    {value}

    <BsChevronDown />
  </button>
));

export default CreateApiKey;
