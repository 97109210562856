import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./i18n";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./Context/AuthContext";

// ReactDOM.render(
//   <Router>
//     <AuthProvider>
//       <App />
//     </AuthProvider>
//   </Router>,
//   document.getElementById("root")
// );

// src/index.js
import posthog from 'posthog-js';
import { PostHogProvider} from 'posthog-js/react'

posthog.init(
  import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY,
  {
    api_host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only',
  }
);


ReactDOM.createRoot(document.getElementById("root")).render(
  <Router>
    <PostHogProvider client={posthog}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </PostHogProvider>
  </Router>
);
