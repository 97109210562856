import { BsStarFill } from "react-icons/bs";

const Stars = ({ stars, size }) => {
  return (
    <div className="flex flex-row gap-1">
      {[0, 1, 2, 3, 4].map((i) => (
        <BsStarFill
          key={i}
          size={size || 16}
          className={i < stars ? "text-yellow-500" : "text-gray-400"}
        />
      ))}
    </div>
  );
};

export default Stars;
