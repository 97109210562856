import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "./Shared/Footer";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import { SlLocationPin } from "react-icons/sl";

import { feedbackIntegration } from "@sentry/react";

import * as Sentry from '@sentry/react';

import { showBugReportDialog } from "../utils/bugreport";

const feedback = feedbackIntegration({
  // Disable injecting the default widget
  autoInject: false,
});

const ErrorFallback = () => {

  const { t } = useTranslation();
  let loggedInState = localStorage.getItem("safetag-username") ? true : false;
  const [loggedIn, setLoggedIn] = React.useState(loggedInState);

  feedback.attachTo(document.querySelector("#your-button"), {
    formTitle: "Report a Bug!",
  });

  return (
    <div className="h-screen flex flex-col">
      <Navbar setLoggedIn={setLoggedIn} loggedIn={loggedIn} />
      <section className="flex-1 px-4 w-full pt-24 pb-12 bg-gradient-to-b from-[#F7DFDF] via-[#FBEFEF88] to-[#ffffff]">
        <div className="w-full max-w-xl mx-auto flex flex-col items-center gap-8">
          {/* <Image src="/assets/404/globe.webp" className="mx-auto w-80" /> */}

          <div
            className="w-72 rounded-2xl p-6 bg-white flex flex-col gap-4 relative mb-12"
            style={{ boxShadow: "-4px 32px 54px 0px #00000026" }}
          >
            <div className="bg-[#D9D9D9] text-[#B1B1B1] rounded-xl text-center font-semibold text-[127px]">
              <div className="-translate-y-4">{":("}</div>
            </div>

            <div className="rounded-lg border p-2 flex flex-row gap-3 items-center">
              <SlLocationPin className="text-lg" />

              <p className="text-lg font-semibold">
                {t("error_boundary.main.image")}
              </p>
            </div>

            <svg
              width="32"
              height="32"
              viewBox="0 0 50 50"
              fill="none"
              className="absolute bottom-0 translate-y-full left-1/2 -translate-x-1/2"
            >
              <path d="M0,0 h50 l-25,50z" fill="white" />
            </svg>
          </div>

          <p className="text-center font-semibold">
            {t("error_boundary.main.desc1")}
          </p>
          <p className="text-center font-semibold">
            {t("error_boundary.main.desc2")}
          </p>
          <button
            onClick={() => {
              localStorage.clear();
              window.location.reload();
              window.location.href = "/";
            }}
            className="px-14 py-3 mt-4 text-white bg-pr hover:bg-sr transition-all rounded-lg "
          >
            {t("error_boundary.main.button")}
          </button>
          <button
          hidden
            id="your-button"
            onClick={() => {
              showBugReportDialog();
            }}
            className="py-3 -mt-7 text-pr font-semibold transition-all rounded-lg "
          >
            {t("error_boundary.main.report")}
          </button>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ErrorFallback;
