// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";

import { toast } from "sonner";

const firebaseConfig = {
  apiKey: "AIzaSyA95iLtDA7f_2XtIZUVLP_y3JURjnJ9T_w",
  authDomain: "warden-350513.firebaseapp.com",
  projectId: "warden-350513",
  storageBucket: "warden-350513.appspot.com",
  messagingSenderId: "343475339854",
  appId: "1:343475339854:web:911f70854dcc021183b91f",
  measurementId: "G-V85FD74W9R",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const messaging = getMessagingFirebase();

//const messaging = getMessaging(app);

export const fetchToken = async () => {
  //console.log(messaging)
  return getToken(messaging, {
    vapidKey:
      "BIePpuhHaZVhodbxKwUgLulpQhsZRjYgsXyR8pDp4_7nI4qhe2wkqjkd8GG8sfCLSY1-2AL-3XBRR2hL9CjcFsU",
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        return "denied";
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      if (
        err.message ===
        "Messaging: The notification permission was not granted and blocked instead. (messaging/permission-blocked)."
      )
        return "denied2";
      else return "failed";
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

function getMessagingFirebase() {
  try {
    const isSupportedBrowser = isSupported();
    if (isSupportedBrowser) {
      let message = getMessaging(app);
      console.log("Firebase initialized");
      return message;
    }
    console.log("Firebase not supported this browser");
    return null;
  } catch (err) {
    console.log("Firebase", err);
    return null;
  }
}
