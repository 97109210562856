import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoClose } from "react-icons/io5";

const AddMember = ({ isOpen, onClose, mutation }) => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const [emailData, setEmailData] = React.useState(null);

  React.useEffect(() => {
    const isValidEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (!isValidEmail) setEmailData(null);
    if (isValidEmail) {
      if (email === "member@test.com") {
        setEmailData({
          email: "member@test.com",
          name: "Member",
          role: "Viewer",
        });
      } else {
        setEmailData({
          email: false,
        });
      }
    }
  }, [email]);

  React.useEffect(() => {
    if (!isOpen) setEmail("");
  }, [isOpen]);

  if (!isOpen) return null;
  return (
    <div
      className="fixed top-0 left-0 h-screen w-full bg-gray-800 bg-opacity-50 z-50 flex flex-col justify-center items-center p-4"
      onClick={onClose}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="max-w-[420px] w-full relative rounded-xl shadow-md bg-white h-full sm:h-fit flex flex-col overflow-hidden p-4 sm:p-8"
      >
        <div className="flex flex-row w-full items-center justify-between mb-4">
          <div className="text-lg font-semibold">{t("account.users.add")}</div>

          <IoClose onClick={onClose} className="cursor-pointer" />
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-sm text-opacity-70 text-black font-medium">
            {t("account.users.add_label")}
          </p>

          <input
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder={t("account.users.add_placeholder")}
            className="w-full h-12 bg-transparent border border-gray-300 rounded-md text-gray-900 "
            type="email"
          />
        </div>
        <div className="flex flex-col flex-1">
          {emailData?.email === false && (
            <div className="flex flex-col items-center gap-4 p-4 mt-4 text-sm text-black text-opacity-70 bg-neutral-100 border rounded">
              {t("account.users.no_user")}
            </div>
          )}

          {emailData?.email ? (
            <div className="flex flex-row gap-2 w-full mt-4">
              <div className="border h-10 w-10 rounded-full overflow-hidden">
                <img
                  className="h-full w-full"
                  src={`https://ui-avatars.com/api/?background=fafafa&color=000&name=${emailData.name}`}
                />
              </div>
              <div className="flex-1 flex flex-col text-sm">
                <p className="font-medium">{emailData.name}</p>
                <p>{emailData.email}</p>
              </div>
              <select
                className="appearance-none bg-transparent border-none outline-none focus:outline-none text-gray-900 h-12"
                value={emailData.role}
                onChange={(e) =>
                  setEmailData((p) => ({ ...p, role: e.target.value }))
                }
              >
                <option value="Viewer">Viewer</option>
                <option value="Admin">Admin</option>
              </select>
            </div>
          ) : null}
        </div>
        <div className="flex flex-col-reverse md:flex-row-reverse justify-start gap-2 w-full mx-auto mt-4">
          <button
            disabled={mutation.isLoading || !emailData?.email}
            className="flex flex-row items-center justify-center gap-2 bg-pr h-12 md:h-9 hover:bg-sr disabled:bg-neutral-500 rounded-md px-4 py-2 transition-all duration-300 text-white"
            onClick={() => {
              mutation.mutate({ emailData });
            }}
          >
            {mutation.isLoading && (
              <AiOutlineLoading3Quarters className="animate-spin" />
            )}
            {mutation.isLoading
              ? t("account.users.adding")
              : t("account.users.add")}
          </button>

          <button
            onClick={onClose}
            className="h-12 md:h-9 rounded-md py-1.5 px-3 text-pr bg-pr bg-opacity-5 border border-sr border-opacity-20 hover:bg-white transition-all duration-500"
          >
            {t("geofences.button.cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddMember;
