import React from "react";
import { useTranslation } from "react-i18next";
import { BsCheckCircleFill, BsStarFill } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import Stars from "./Stars";
import Draggable from "./Draggable";

const ProductEmbed = ({ product, reverse = false }) => {
  const { t } = useTranslation();
  const [swiperRef, setSwiperRef] = React.useState(null);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [language, setLanguage] = React.useState("en");

  //Located listener method to update language and currency and customer zone display values
  window.addEventListener("located", () => {
    var user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
    setLanguage(user_prefs.language);
  });

  React.useEffect(() => {
    if (localStorage.getItem("user-prefsv2") != null) {
      let user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
      setLanguage(user_prefs.language);
    }
  }, []);

  const reviews = (
    product.display.reviews[language]
      ? product.display.reviews[language]
      : product.display.reviews.en
  ).slice(0, 10);

  const innerRef = React.useRef();

  return (
    <div className="flex flex-col gap-4 md:rounded-2xl p-0 md:p-9 lg:p-20 md:bg-white md:border max-w-6xl w-full">
      <div
        className={`flex flex-col ${
          reverse ? "md:flex-row-reverse lg:flex-row" : "md:flex-row-reverse"
        } gap-16 w-full justify-between`}
      >
        <div className="flex-1 md:flex-[0.7] lg:flex-[0.9] flex flex-col">
          <img src={product.images[0]} className="object-cover" />
        </div>
        <div className="flex-1 flex flex-col items-start gap-8">
          <h3 className="font-bold text-3xl md:text-4xl">
            {product.display.name[language] || product.display.name.en}
          </h3>
          <div className="flex flex-row gap-6 items-center font-semibold">
            <div className="flex flex-row gap-1 items-center">
              Average <span className="text-pr">5</span>{" "}
              <BsStarFill className="text-yellow-300" /> Rating
            </div>
            <div>
              <span className="text-pr">{product.display.sales}</span> Sales
            </div>
          </div>
          <p className="text-black text-opacity-70">
            {product.display.desc[language] || product.display.desc.en}
          </p>

          <div className="flex md:hidden lg:flex flex-col gap-2 ">
            {product.display.points[
              product.display.points[language] ? language : "en"
            ].map((point, index) => (
              <div key={index} className="flex flex-row items-center gap-2">
                <BsCheckCircleFill className="text-pr" />
                <p className="text-black text-opacity-70">{point}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="hidden md:flex lg:hidden flex-col gap-2 ">
        {product.display.points[
          product.display.points[language] ? language : "en"
        ].map((point, index) => (
          <div key={index} className="flex flex-row items-center gap-2">
            <BsCheckCircleFill className="text-pr" />
            <p className="text-black text-opacity-70">{point}</p>
          </div>
        ))}
      </div>
      <div className="w-full flex flex-col mt-4 max-w-[100%] overflow-hidden">
        <Draggable innerRef={innerRef} rootClass="no-scrollbar px-4">
          <div
            ref={innerRef}
            className="flex flex-row gap-4 w-full overflow-auto scroll_product_embed px-4"
          >
            {reviews.map((review, index) => (
              <div className="py-8" key={index}>
                <div
                  className="flex flex-col gap-4 p-6 rounded-2xl border w-96 h-[250px] overflow-hidden  select-none cursor-pointer scroll_product_reviews"
                  style={{ boxShadow: "0px 0px 16px 5px #0000000D" }}
                >
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row gap-1 items-center">
                      <p className="font-semibold">{review.stars}/5</p>
                      <Stars stars={review.stars} />
                    </div>
                    <div className="font-semibold text-right">
                      {review.reviewer}
                    </div>
                  </div>
                  <div className="text-pr font-bold">{review.title}</div>
                  <div className="text-black text-opacity-70 flex-col flex-1 overflow-y-auto">
                    {review.body}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Draggable>
        {/* <Swiper
          slidesPerView={1}
          spaceBetween={20}
          className="w-full"
          onInit={(e) => setSwiperRef(e)}
          onSlideChange={(e) => setActiveIndex(e.activeIndex)}
          breakpoints={{
            768: {
              slidesPerView: 1.5,
            },
            1024: {
              slidesPerView: 2.5,
            },
          }}
        >
          {reviews.map((review, index) => (
            <SwiperSlide key={index} style={{ height: "auto" }}>
              <div className="py-8 first:md:ml-4 last:md:mr-4">
                <div
                  className="flex flex-col gap-4 p-6 rounded-2xl border h-[250px] overflow-hidden  select-none cursor-pointer scroll_product_reviews"
                  style={{ boxShadow: "0px 0px 16px 5px #0000000D" }}
                >
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row gap-1 items-center">
                      <p className="font-semibold">{review.stars}/5</p>
                      <Stars stars={review.stars} />
                    </div>
                    <div className="font-semibold text-right">
                      {review.reviewer}
                    </div>
                  </div>
                  <div className="text-pr font-bold">{review.title}</div>
                  <div className="text-black text-opacity-70 flex-col flex-1 overflow-y-auto">
                    {review.body}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper> */}
      </div>
      {/* <div className="w-full flex flex-row h-3 mt-2 bg-[#E7EAEE] rounded-full overflow-hidden relative mx-auto">
        <div
          draggable
          className={`bg-pr rounded-full transition-all absolute left-0 top-0 h-full z-[1]`}
          style={{
            width: `${100 / (reviews.length - 1)}%`,
            transform: `translateX(${100 * activeIndex}%)`,
          }}
        ></div>
        {Array.from({ length: reviews.length - 1 }, (i) => i).map((a, i) => (
          <div
            className="flex-1 relative z-[2] cursor-pointer"
            onClick={() => swiperRef.slideTo(i)}
          ></div>
        ))}
      </div> */}
    </div>
  );
};

export default ProductEmbed;
