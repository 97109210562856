import React from "react";
import { useTranslation } from "react-i18next";
import Image from "../Shared/Image";

const StaticSection = () => {
  const { t } = useTranslation();
  return (
    <section className="w-full md:px-4 py-20 flex flex-col items-center">
      <div className="grid grid-cols-1 md:grid-cols-2 w-full max-w-6xl gap-10 lg:gap-20">
        <div className="md:col-span-2 flex flex-col gap-4 px-4 md:px-0">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold">
            {t("asset.static1.title")}
          </h2>
          <p className="text-black text-opacity-70 lg:w-3/5">
            {t("asset.static1.desc")}
          </p>
          <Image
            src="/assets/asset-tracking/map.webp"
            className="hidden md:block"
          />
        </div>
        <Image
          src="/assets/asset-tracking/map.webp"
          className="md:hidden mb-8 w-full"
        />
        <div className="flex flex-col gap-4 px-4 md:px-0">
          <Image src="/assets/asset-tracking/battery.webp" />
          <h2 className="text-2xl lg:text-3xl font-bold">
            {t("asset.static2.title")}
          </h2>
          <p className="text-black text-opacity-70">
            {t("asset.static2.desc")}
          </p>
        </div>
        <div className="flex flex-col gap-4 px-4 md:px-0">
          <Image src="/assets/asset-tracking/envelop.webp" />
          <h2 className="text-2xl lg:text-3xl font-bold">
            {t("asset.static3.title")}
          </h2>
          <p className="text-black text-opacity-70">
            {t("asset.static3.desc")}
          </p>
        </div>
      </div>
    </section>
  );
};

export default StaticSection;
