import { Link } from "react-router-dom";
import Stars from "./Stars";
import React from "react";

var zone_state = "UK";

const ProductCard = ({ product }) => {
  const [language, setLanguage] = React.useState("en");
  const [currency, setCurrency] = React.useState({
    code: "GBP",
    name: "British Sterling",
    symbol: "£",
  });
  const [zone, setZone] = React.useState(zone_state);

  //Located listener method to update language and currency and customer zone display values
  window.addEventListener("located", () => {
    var user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
    setLanguage(user_prefs.language);
    setCurrency(user_prefs.currency);
    setZone(user_prefs.customer_zone);
  });

  React.useEffect(() => {
    if (localStorage.getItem("user-prefsv2") != null) {
      let user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
      setCurrency(user_prefs.currency);
      setLanguage(user_prefs.language);
      setZone(user_prefs.customer_zone);
    }
  }, []);
  return (
    <div className="rounded-xl h-full p-3 bg-white flex flex-col gap-4 justify-between border shadow-sm select-none">
      <div>
        <div className="w-full rounded-md overflow-hidden h-56 bg-[#f1f1f1] bg-opacity-50">
          <img
            src={product.cutout}
            alt={
              product.display.name[language]
                ? product.display.name[language]
                : product.display.name.en
            }
            className="w-full h-full object-contain py-4"
            loading="lazy"
          />
        </div>
        <div className="my-2">
          <Stars stars={5} />
        </div>
        <div className="flex flex-row justify-between py-3">
          <p className="font-bold text-xl">
            {product.display.name[language]
              ? product.display.name[language]
              : product.display.name.en}
          </p>
          {product.prices ? (
            <p className="font-bold text-pr text-xl">
              {currency.symbol}
              {(
                product.prices[zone].single[currency.code.toLowerCase()] / 100
              ).toFixed(2)}
            </p>
          ) : null}
        </div>
        <p className="text-sm text-semibold py-1 leading-6">
          {product.display.desc[language]
            ? product.display.desc[language]
            : product.display.desc.en}
        </p>
      </div>
      <Link
        className="text-pr bg-[#F7DFDF] hover:bg-[#f8c7c7] rounded-lg p-3 w-full text-center font-semibold transition-all"
        to={`/product/${product.name}`}
      >
        View Product
      </Link>
    </div>
  );
};

export default ProductCard;
