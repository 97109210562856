import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IoMdBook } from "react-icons/io";

const Docs = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <hr className="my-4" />
      <div className="flex flex-row items-center justify-end gap-2 py-3">
        <Link
          to="https://safetagtracking.readme.io/reference/getting-started-with-your-api"
          target="_blank"
          className="flex flex-row gap-2 items-center border-b border-transparent text-pr hover:text-sr font-medium hover:border-sr transition-all duration-300 pb-1"
        >
          <IoMdBook className="text-xl" />
          {t("account.api.docs")}
        </Link>
      </div>
    </div>
  );
};

export default Docs;
