import moment from "moment";

export default function useTime(time, format, rules) {
  if (!time) return null;
  const isUSA =
    JSON.parse(localStorage.getItem("user-localev2"))?.location
      ?.country_code === "US";
  if (format) {
    return moment(time).format(format);
  }
  if (rules) {
    return moment(time).format(isUSA ? rules.US : rules.default);
  }

  return moment(time).format(isUSA ? "HH:mm MM-DD-YY" : "HH:mm DD-MM-YY");
}
