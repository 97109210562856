import React from "react";

const getSrcSet = (src) => {
  const split = src.split("/");
  const name = split.pop();
  const path = split.join("/");
  const sets = ["sm_320w", "md_680w", "lg_960w", "xl_1980w"];
  for (let i = 0; i < sets.length; i++) {
    let [a, b] = sets[i].split("_");
    sets[i] = `${path}/${a}/${name} ${b}`;
  }
  return sets.join(", ");
};

const Image = ({ className, src, alt, style, notLazy }) => {
  return (
    <img
      style={style}
      src={src}
      className={className}
      alt={alt || ""}
      srcSet={getSrcSet(src)}
      loading={notLazy ? "eager" : "lazy"}
    />
  );
};

export default Image;
