import React from "react";
import { useTranslation } from "react-i18next";
import { BsCopy, BsInfoCircle, BsSend, BsShare } from "react-icons/bs";
import { Link, useOutletContext } from "react-router-dom";
import { formatPrice } from "../../../utils/price";
import { useMutation, useQuery } from "react-query";
import { handleGetCredits, handleInviteReferral } from "../../../utils/api";
import { toast } from "sonner";
//usePosthog
import { usePostHog } from 'posthog-js/react'

const Referrals = () => {
  const posthog = usePostHog();
  const { t } = useTranslation();
  const { userDetails } = useOutletContext();
  const [truncated, setTruncated] = React.useState(true);

  const [email, setEmail] = React.useState("");
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const credits = useQuery({
    queryKey: ["credits"],
    queryFn: handleGetCredits,
  });

  const invite = useMutation({
    mutationFn: handleInviteReferral,
    onSuccess: () => {
      toast.success(t("account.referral.success"));
      setEmail("");
    },
    onError: (e) => {
      console.log(e);
      if (e.response.data.message === "Referee is already a user.") {
        toast.error(t("account.referral.already_error"));
      } else if (
        e.response.data.message ===
        "You have reached the maximum number of invites for today."
      ) {
        toast.error(t("account.referral.limit_error"));
      } else if (
        e.response.data.message ===
        "You have reached the maximum number of invites for today."
      ) {
        toast.error(t("account.referral.limit_error"));
      }
    },
  });

  const priceA = formatPrice(-credits?.data?.balance, credits?.data?.currency);
  const priceB = formatPrice(
    userDetails?.data?.marketing?.referral_balance,
    userDetails?.data?.currency
  );

  const data = {
    title: "Join SafeTag Tracking",
    text: `${t('account.referral.share_text')}: https://safetagtracking.com/signup?referrer=${userDetails?.data?.username}`,
    url: `https://safetagtracking.com/signup?referrer=${userDetails?.data?.username}`,
  };

  return (
    <div className="w-full sm:border rounded-lg flex flex-col mt-6 overflow-hidden gap-6 p-0 sm:p-8">
      <div className="flex flex-col gap-2">
        <h2 className="font-bold text-2xl">{t("account.referral.title")}</h2>
        <p className="opacity-60">{t("account.referral.desc1")}</p>
        <p className="opacity-60 text-sm hidden sm:block">
          {t("account.referral.desc2")}
        </p>
        <p className="text-black text-opacity-60 text-sm sm:hidden">
          {t("account.referral.desc2").slice(0, truncated ? 100 : undefined)}
          {truncated ? (
            <span
              onClick={() => setTruncated(false)}
              className="text-pr opacity-100"
            >
              ... {t("account.referral.read_more")}
            </span>
          ) : (
            <>
              <br />
              <span
                onClick={() => setTruncated(true)}
                className="text-pr opacity-100"
              >
                {t("account.referral.read_less")}
              </span>
            </>
          )}
        </p>
        <Link
          to="/referrals"
          target="_blank"
          className="text-pr hover:text-sr transition-all duration-300 font-medium text-sm"
        >
          {t("account.referral.learn")}
        </Link>
      </div>

      <div className="flex flex-col sm:flex-row sm:items-center gap-2 justify-between w-full bg-[#d9d9d932] border rounded-lg p-4">
        <div className="text-lg font-medium flex flex-row gap-2 items-center">
          {t("account.referral.balance")}
          <div className="relative group">
            <p className="absolute pointer-events-none z-[100] bottom-6 -translate-x-1/2 left-1/2 bg-stone-800 bg-opacity-90 delay-100 group-hover:delay-700 text-white text-xs font-light opacity-0 w-64 rounded group-hover:opacity-100 transition-all duration-300 p-2">
              {t("account.referral.tooltip")}
            </p>
            <BsInfoCircle className="cursor-pointer text-gray-400 hover:text-pr transition-all duration-300" />
          </div>
        </div>
        <div className="font-semibold text-pr text-2xl flex flex-row gap-3 items-end">
          {priceA} <span className="text-gray-400 text-lg">/{priceB}</span>
        </div>
      </div>

      <div className="flex flex-col gap-2 w-full border rounded-lg p-4">
        <h4 className="font-semibold">{t("account.referral.link_title")}</h4>
        <p className="opacity-60">{t("account.referral.link_desc")}</p>

        <div className="flex flex-row flex-wrap items-center justify-between mt-4 gap-x-4 gap-y-2">
          <div className="p-3 border rounded-lg flex-1 text-sm">
            safetagtracking.com/signup?referrer=
            {userDetails?.data?.username}
          </div>
          <div className="flex flex-col sm:flex-row gap-3 items-stretch sm:items-center">
            <button
              className="btn-pr flex flex-row justify-center items-center gap-2"
              onClick={() => {
                posthog.capture('copy_referral_link');
                navigator.clipboard.writeText(
                  `https://safetagtracking.com/signup?referrer=${userDetails?.data?.username}`
                );
                toast.success(t("account.referral.link_copied"));
              }}
            >
              <BsCopy className="-scale-x-100" />
              {t("account.referral.link_btn1")}
            </button>
            {navigator.canShare(data) && (
              <button
              className="btn-pr flex flex-row justify-center items-center gap-2"
              onClick={() => {
                posthog.capture('share_referral_link');
                if (navigator.share && navigator.canShare(data)) {
                  navigator.share(data);
                } else {
                  console.log("Sharing not supported");
                  // Do something else like copying the data to the clipboard
                }
              }}
            >
              <BsShare />
              {t("account.referral.link_btn2")}
            </button>)
            }
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2 w-full border rounded-lg p-4">
        <h4 className="font-semibold">{t("account.referral.invite_title")}</h4>
        <p className="opacity-60">{t("account.referral.invite_desc")}</p>

        <div className="flex flex-col sm:flex-row items-start justify-between  mt-4 gap-2">
          <div className="flex flex-col w-full">
            <input
              className="border rounded-lg w-full p-3"
              placeholder={t("account.referral.invite_placeholder")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {!isValidEmail(email) && email.trim().length ? (
              <p className="text-red-500 mt-1 text-sm">
                {t("account.referral.email_error")}
              </p>
            ) : null}
          </div>
          <button
            onClick={() => {
              posthog.capture('email_referral_link', { email: email });
              invite.mutate({ email: email });
            }}
            disabled={!isValidEmail(email)}
            className="btn-pr flex flex-row justify-center items-center gap-2 text-nowrap disabled:opacity-30"
          >
            <BsSend className="rotate-45 -translate-x-2" />
            {t("account.referral.invite_btn")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Referrals;
