import React from "react";
import { IoPersonAddOutline } from "react-icons/io5";
import AddMember from "./AddMember";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteMember from "./DeleteMember";
import { useTranslation } from "react-i18next";

const Sharing = () => {
  const { t } = useTranslation();

  const [members, setMembers] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selected, setSelected] = React.useState(null);

  const mutate = ({ emailData }) => {
    setIsLoading(true);
    setTimeout(() => {
      setMembers([...members, emailData]);
      setIsLoading(false);
      setShowModal(false);
    }, 500);
  };

  const deleteMember = React.useCallback(() => {
    setMembers((prev) => {
      return prev.filter((_, i) => i !== selected);
    });
    setSelected(null);
  }, [selected]);

  return (
    <div className="w-full sm:border rounded-lg flex flex-col mt-6 gap-6 p-0 sm:p-8">
      <AddMember
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        mutation={{ mutate, isLoading }}
      />

      <DeleteMember
        isOpen={selected !== null}
        onClose={() => setSelected(null)}
        mutation={{ mutate: deleteMember }}
        member={members[selected]}
      />

      <div className="flex flex-col sm:flex-row gap-4 sm:items-center justify-between">
        <div className="flex flex-col gap-2">
          <h2 className="text-lg font-semibold">{t("account.users.title")}</h2>
          <p>{t("account.users.subheading")}</p>
        </div>
        {members.length > 0 && (
          <button
            onClick={() => setShowModal(true)}
            className="rounded-lg py-3 px-6 bg-pr hover:bg-sr text-white transition-all duration-300 flex flex-row items-center justify-center gap-2"
          >
            <IoPersonAddOutline className="text-xl -scale-x-100" />
            {t("account.users.add")}
          </button>
        )}
      </div>
      {members.length === 0 && (
        <div className="flex flex-col sm:items-center gap-4 p-4 py-8 bg-neutral-100 border rounded-xl">
          <p className="text-center w-full max-w-sm opacity-70">
            {t("account.users.no_members")}
          </p>
          <button
            onClick={() => setShowModal(true)}
            className="rounded-lg py-3 px-6 bg-pr hover:bg-sr text-white transition-all duration-300 flex flex-row items-center justify-center gap-2"
          >
            <IoPersonAddOutline className="text-xl -scale-x-100" />
            {t("account.users.add")}
          </button>
        </div>
      )}

      {members.length > 0 && (
        <div>
          <p className="font-semibold text-black text-opacity-70">
            People with access
          </p>
          <div className="flex flex-col gap-2 mt-3">
            {members.map((member, index) => (
              <div
                className="flex flex-row gap-3 w-full p-3 items-center border rounded-md"
                key={index}
              >
                <div className="border h-10 w-10 rounded-full overflow-hidden">
                  <img
                    className="h-full w-full"
                    src={`https://ui-avatars.com/api/?background=fafafa&color=000&name=${member.name}`}
                  />
                </div>
                <div className="flex-1 flex flex-col text-sm">
                  <p className="font-medium">{member.name}</p>
                  <p>{member.email}</p>
                </div>
                <select
                  className="appearance-none bg-transparent border-none outline-none focus:outline-none text-gray-900 h-12"
                  value={member.role}
                >
                  <option value="Viewer">Viewer</option>
                  <option value="Admin">Admin</option>
                </select>
                <RiDeleteBin6Line
                  className="text-2xl cursor-pointer"
                  onClick={() => setSelected(index)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Sharing;
