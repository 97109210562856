import React from "react";
import Image from "../Shared/Image";
import { useTranslation } from "react-i18next";

const featuresList = [
  {
    image: "/assets/wireless/global.svg",
    title: "wireless.features.global_coverage",
  },
  {
    image: "/assets/asset-tracking/battery.svg",
    title: "asset.main.battery",
  },
  {
    image: "/assets/asset-tracking/gps.svg",
    title: "asset.main.gps",
  },
  {
    image: "/assets/home/icon_rta.svg",
    title: "home.app.alerts_title",
  },
];

const HeroSection = () => {
  const { t } = useTranslation();
  return (
    <section className="w-full px-4 py-12 md:py-20 flex flex-col items-center bg-gradient-to-b from-[#F7DFDF80] to-[#ffffff00] relative overflow-hidden">
      <img
        src="/assets/wireless/bg.svg"
        className="absolute z-0 w-[300%] md:w-full lg:w-2/3 h-full -top-1/3 md:-top-1/2 lg:top-0 left-2/3 md:left-1/3 lg:left-1/2"
      />
      <div className="flex flex-col md:flex-row justify-between items-center w-full max-w-6xl z-[2]">
        <div className="flex flex-col gap-4 max-w-[570px]">
          <h1 className="font-bold text-4xl md:text-5xl">
            {t("asset.main.title")}
          </h1>
          <p className="text-lg text-black text-opacity-70">
            {t("asset.main.desc1")}
          </p>
          <p className="text-lg text-black text-opacity-70 hidden md:block">
            {t("asset.main.desc2")}
          </p>
          <p className="text-lg text-black text-opacity-70 hidden md:block">
            {t("asset.main.desc3")}
          </p>
        </div>
        <div className="w-full max-w-[500px]">
          <Image
            src="/assets/asset-tracking/hero.webp"
            className="w-full max-w-[500px]"
          />
        </div>
      </div>

      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 relative z-[2] mt-12 w-full max-w-6xl">
        {featuresList.map((f, i) => (
          <div
            key={i}
            className="py-4 md:py-8 px-4 md:px-6 gap-1 flex flex-col items-center text-center md:flex-row md:text-left lg:flex-col lg:items-start bg-white border rounded-xl"
          >
            <img src={f.image} className="h-12 w-12" />
            <p className="text-lg font-bold text-black text-opacity-70">
              {t(f.title)}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HeroSection;
