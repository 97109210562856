//Import the Sentry library
import * as Sentry from '@sentry/react';

export function showBugReportDialog() {
    Sentry.showReportDialog({
        // ...
        onClose() {
            // Refresh the page after the user closes the report dialog
            location.reload();
        },
    });
}
