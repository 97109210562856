import React from "react";
import Image from "../Shared/Image";
import { useTranslation } from "react-i18next";

const FeaturesSection = () => {
  const { t } = useTranslation();
  return (
    <section className="w-full px-4 py-20 flex flex-col items-center gap-14">
      <div
        className="flex flex-col lg:flex-row items-center gap-10 p-6 md:p-10 border rounded-2xl w-full max-w-6xl"
        style={{ boxShadow: "0px 0px 42px 5px #0000000D" }}
      >
        <div className="flex flex-col items-start gap-4 md:gap-6 w-full lg:flex-1">
          <h2 className="text-3xl lg:text-4xl font-bold">
            {t("asset.feature1.title")}
          </h2>
          <p className="text-black text-opacity-70 md:text-lg">
            {t("asset.feature1.desc")}
          </p>
        </div>
        <div className="flex flex-col items-center lg:flex-1">
          <Image
            src="/assets/asset-tracking/aerial.webp"
            className="hidden md:block md:max-w-lg lg:w-full"
          />
          <Image
            src="/assets/asset-tracking/aerial.webp"
            className="block md:hidden"
          />
        </div>
      </div>
      <div
        className="flex flex-col lg:flex-row-reverse items-center gap-10 p-6 md:p-10 border rounded-2xl w-full max-w-6xl"
        style={{ boxShadow: "0px 0px 42px 5px #0000000D" }}
      >
        <div className="flex flex-col items-start gap-4 md:gap-6 w-full lg:flex-1">
          <h2 className="text-3xl lg:text-4xl font-bold">
            {t("asset.feature2.title")}
          </h2>
          <p className="text-black text-opacity-70 md:text-lg">
            {t("asset.feature2.desc")}
          </p>
        </div>
        <div className="flex flex-col items-center lg:flex-1">
          <Image
            src="/assets/asset-tracking/lifter.webp"
            className="hidden md:block md:max-w-lg lg:w-full"
          />
          <Image
            src="/assets/asset-tracking/lifter.webp"
            className="block md:hidden"
          />
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
