import React from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters, AiOutlineMail } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const DeleteWebhookModal = ({ isOpen, onClose, mutation }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;
  return (
    <div
      className="fixed top-0 left-0 h-screen w-full bg-gray-800 bg-opacity-50 z-50 flex flex-col justify-center items-center p-4"
      onClick={onClose}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="max-w-[420px] w-full relative rounded-xl shadow-md bg-white max-h-[90vh] flex flex-col overflow-hidden p-8"
      >
        <div className="flex flex-row w-full items-center justify-between mb-4">
          <div className="text-lg font-semibold">
            {t("account.webhook.delete_title")}
          </div>

          <IoClose onClick={onClose} className="cursor-pointer" />
        </div>

        <p className="text-sm text-opacity-70 text-black font-medium">
          {t("account.webhook.delete_msg")}
        </p>

        {mutation.isError && (
          <div className="text-red-500 py-2 text-sm translate-y-6">{error}</div>
        )}

        <div className="flex flex-col-reverse md:flex-row-reverse justify-start gap-2 w-full mx-auto mt-8">
          <button
            disabled={mutation.isLoading}
            className="flex flex-row items-center justify-center gap-2 bg-pr h-12 md:h-9 hover:bg-sr disabled:bg-neutral-500 rounded-md px-4 py-2 transition-all duration-300 text-white"
            onClick={mutation.mutate}
          >
            {mutation.isLoading && (
              <AiOutlineLoading3Quarters className="animate-spin" />
            )}
            {mutation.isLoading
              ? t("account.webhook.removing")
              : t("account.webhook.remove")}
          </button>

          <button
            onClick={onClose}
            className="h-12 md:h-9 rounded-md py-1.5 px-3 text-pr bg-pr bg-opacity-5 border border-sr border-opacity-20 hover:bg-white transition-all duration-500"
          >
            {t("geofences.button.cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteWebhookModal;
