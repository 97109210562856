import React from "react";
import { useOutletContext } from "react-router-dom";
import UserInfo from "./UserInfo";
import DeleteUser from "./DeleteUser";

const General = () => {
  const { userDetails, setLoggedIn, refreshUser } = useOutletContext();

  return (
    <div className="w-full border rounded-lg flex flex-col mt-6 overflow-hidden">
      <UserInfo userDetails={userDetails} refreshUser={refreshUser} />
      <DeleteUser setLoggedIn={setLoggedIn} userDetails={userDetails} />
    </div>
  );
};

export default General;
