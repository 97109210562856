import React from "react";
import { useTranslation } from "react-i18next";
import ProductEmbed from "../ProductEmbed";
import { Link } from "react-router-dom";

const FeaturedProducts = ({ products, title }) => {
  const { t } = useTranslation();

  return (
    <section className="w-full px-4 py-20 flex flex-col gap-12 items-center bg-gradient-to-b from-[#F7DFDF33] to-[#FFFFFF00]">
      <h2 className="text-3xl md:text-5xl font-bold w-full max-w-6xl">
        {title}
      </h2>
      {products.isSuccess && (
        <>
          {products.data
            .sort(() => Math.random() - 0.5)
            .slice(0, 2)
            .map((product, i) => (
              <ProductEmbed
                key={i}
                product={product}
                reverse={i % 2 ? true : false}
              />
            ))}

          <Link
            className="rounded-lg bg-pr hover:bg-sr text-white transition-all duration-300 py-3 px-6 mx-auto"
            to="/shop"
          >
            {t("home.products.shop_button")}
          </Link>
        </>
      )}
    </section>
  );
};

export default FeaturedProducts;
