import React from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../Context/AuthContext";
import { useOutletContext } from "react-router-dom";
import { toast } from "sonner";
import {
  createAppToken,
  deleteAppToken,
  getAppToken,
} from "../../../../utils/api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useEscapeKey from "../../../../hooks/EscapeKey";
import ReactDatePicker from "react-datepicker";
import { MdAdd, MdDeleteOutline } from "react-icons/md";
import useTime from "../../../../hooks/Time";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteApiKey from "./modals/DeleteApiKey";
import CreateApiKey from "./modals/CreateApiKey";
import { IoMdClose, IoMdCopy } from "react-icons/io";

const ApiKeys = () => {
  const { userDetails, refreshUser } = useOutletContext();
  const { t } = useTranslation();
  const [deleteId, setDeleteId] = React.useState(null);
  const [createModal, setCreateModal] = React.useState(false);
  const [expiry, setExpiry] = React.useState(false);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [expiryDate, setExpiryDate] = React.useState(tomorrow);
  const { refreshLogin } = React.useContext(AuthContext);
  const [token, setToken] = React.useState(null);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(token);
    toast.success(t("account.api.copy"));
  };

  const appToken = useQuery({
    queryKey: ["appToken"],
    queryFn: getAppToken,
    onError: async (e) => {
      if (
        e?.response?.data?.message === "jwt expired" ||
        e?.response?.data?.message === "jwt malformed" ||
        e?.response?.data?.message === "invalid token"
      ) {
        await refreshLogin();
        appToken.refetch();
        return;
      }
    },
  });

  const queryClient = useQueryClient();

  const createToken = useMutation({
    mutationFn: () => createAppToken(expiry, expiryDate),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["appToken"]);
      setCreateModal(false);
      setToken(data.application_token);
    },
    onError: async (e) => {
      if (
        e?.response?.data?.message === "jwt expired" ||
        e?.response?.data?.message === "jwt malformed" ||
        e?.response?.data?.message === "invalid token"
      ) {
        await refreshLogin();
        createToken.mutate();
        return;
      } else toast.error(t("account.api.create_error"));
    },
  });

  const deleteToken = useMutation({
    mutationFn: () => deleteAppToken(deleteId),
    onSuccess: () => {
      toast.success(t("account.api.delete_success"));
      queryClient.invalidateQueries(["appToken"]);
      setDeleteId(null);
    },
    onError: async (e) => {
      if (
        e?.response?.data?.message === "jwt expired" ||
        e?.response?.data?.message === "jwt malformed" ||
        e?.response?.data?.message === "invalid token"
      ) {
        await refreshLogin();
        deleteToken.mutate();
        return;
      } else toast.error(t("account.api.delete_error"));
    },
  });

  useEscapeKey(() => setDeleteId(null) || setToken(null));
  return (
    <div>
            {token && (
        <div
          className="fixed top-0 left-0 h-screen w-full bg-gray-300 bg-opacity-50 z-50 flex flex-col justify-center items-center p-4"
          onClick={() => setToken(null)}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-full max-w-lg relative rounded-xl shadow-md bg-white max-h-[90vh] flex flex-col overflow-hidden p-4 items-center "
          >
            <div className="flex">
              <div className="py-8 font-bold mr-4">{token}</div>
              <button
                onClick={copyToClipboard}
                className="text-sr hover:text-pr focus:outline-none"
              >
                <IoMdCopy className="text-xl" />
              </button>
            </div>
          </div>
        </div>
      )}

      <DeleteApiKey
        isOpen={deleteId !== null}
        onClose={() => setDeleteId(null)}
        mutation={deleteToken}
      />
      <CreateApiKey
        isOpen={createModal}
        onClose={() => setCreateModal(false)}
        mutation={createToken}
        expiry={expiry}
        setExpiry={setExpiry}
        expiryDate={expiryDate}
        setExpiryDate={setExpiryDate}
        tomorrow={tomorrow}
      />
      <div className="flex flex-row w-full items-center justify-between">
        <div className="font-semibold">{t("account.api.api_keys")}</div>
        <div className="hidden sm:flex flex-row gap-2">
          <button
            onClick={() => setCreateModal(true)}
            className="flex flex-row items-center gap-2 bg-pr hover:bg-sr py-2 text-sm px-3 transition-all duration-500 rounded-md text-white"
          >
            <MdAdd />
            {t("account.api.new_key")}
          </button>
        </div>
      </div>
      {appToken.data?.length === 0 ? (
        <div className="mt-6 text-black text-opacity-70 text-center">
          {t("account.api.no_tokens")}
        </div>
      ) : appToken.isLoading ? (
        <img
          className="loader h-8 m-6"
          src="/assets/icons/loading.svg"
          alt="logo"
        />
      ) : (
        <>
          <div className="border rounded-lg w-full mt-3 hidden sm:flex">
            <table className="w-full table-auto text-left text-sm">
              <thead>
                <tr className="border-b bg-neutral-100">
                  <th className="p-3 font-semibold border-r">ID</th>
                  <th className="p-3 font-semibold border-r">
                    {t("account.api.created")}
                  </th>
                  <th className="p-3 font-semibold">
                    {t("account.api.expires")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {appToken.data?.map((item) => {
                  return (
                    <tr
                      key={item._id}
                      className="border-b last:border-none text-black text-opacity-70"
                    >
                      <td className="p-3 border-r font-mono">{item._id}</td>
                      <td className="p-3 border-r">
                        {useTime(item.created_at)}
                      </td>
                      <td className="p-3 flex flex-row justify-between items-center">
                        <p>{useTime(item.expires_at) || "No Expiry Set"}</p>
                        <RiDeleteBin6Line
                          onClick={() => {
                            setDeleteId(item._id);
                          }}
                          className="text-xl text-neutral-600 cursor-pointer hover:text-neutral-800"
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex sm:hidden flex-col gap-4 mt-3">
            {appToken.data?.map((item) => (
              <div className="flex flex-row gap-2 items-start p-3 rounded-md border">
                <div className="flex flex-col gap-2 text-sm flex-1">
                  <div className="flex flex-row gap-1 font-medium">
                    <span className="font-normal text-black text-opacity-70">
                      ID
                    </span>{" "}
                    {item._id}
                  </div>
                  <div className="flex flex-row gap-1 font-medium">
                    <span className="font-normal text-black text-opacity-70">
                      {t("account.api.created")}
                    </span>{" "}
                    {useTime(item.created_at)}
                  </div>
                  <div className="flex flex-row gap-1 font-medium">
                    <span className="font-normal text-black text-opacity-70">
                      {t("account.api.expires")}
                    </span>{" "}
                    {useTime(item.expires_at) || "No Expiry Set"}
                  </div>
                </div>
                <RiDeleteBin6Line
                  onClick={() => {
                    setDeleteId(item._id);
                  }}
                  className="text-xl text-neutral-600 cursor-pointer hover:text-neutral-800"
                />
              </div>
            ))}
            <button
              onClick={() => setCreateModal(true)}
              className="flex flex-row items-center justify-center gap-2 bg-pr hover:bg-sr py-3 text-sm px-3 transition-all duration-500 rounded-md text-white"
            >
              <MdAdd />
              {t("account.api.new_key")}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ApiKeys;
