/* eslint-disable no-undef */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BsFacebook, BsLinkedin, BsYoutube } from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import { AiFillInstagram } from "react-icons/ai";
import { FaGlobeAfrica } from "react-icons/fa";
import { RiArrowUpSFill, RiArrowDownSFill } from "react-icons/ri";
import {
  currencyList,
  languageList,
  currencyArray,
  languageArray,
} from "../../constants/localization";
import { useTranslation } from "react-i18next";

var currency = currencyList.GBP;
var language = languageList.en;

function updateCurrency(currency) {
  var user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
  user_prefs.currency = currencyList[currency];
  localStorage.setItem("user-prefsv2", JSON.stringify(user_prefs));
  window.location.reload();
}

function updateLanguage(language) {
  var user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
  user_prefs.language = language;
  localStorage.setItem("user-prefsv2", JSON.stringify(user_prefs));
  localStorage.setItem("i18nextLng", language);
  window.location.reload();
}

const Footer = () => {
  window.addEventListener("located", () => {
    var user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
    setSelectedCur(user_prefs.currency);
    setSelectedLang(
      t("localization.languages." + user_prefs.language)
        ? t("localization.languages." + user_prefs.language)
        : "English"
    );
  });

  const { t } = useTranslation();

  if (localStorage.getItem("user-localev2") != null) {
    var user_prefs = JSON.parse(localStorage.getItem("user-prefsv2"));
    currency = user_prefs.currency;
    language = t("localization.languages." + user_prefs.language)
      ? t("localization.languages." + user_prefs.language)
      : "English";
  }

  const [currShow, setCurrShow] = useState(false);
  const [selectedCur, setSelectedCur] = useState(currency);
  const [langShow, setLangShow] = useState(false);
  const [selectedLang, setSelectedLang] = useState(language);

  return (
    <div className="rounded-t-xl px-3 w-full flex flex-col items-center justify-center z-[2] relative bg-[#111]">
      <img
        src="/assets/home/logo_white.webp"
        className="w-20 object-contain block md:hidden mx-auto mt-2"
        alt=""
        loading="lazy"
      />
      <div className="flex flex-col md:flex-row gap-4 justify-between items-center w-full max-w-6xl text-white py-8 border-b">
        <p>{t("footer.download.title")}</p>
        <div className="flex flex-row gap-3">
          <a
            className=""
            href="https://apps.apple.com/us/app/safetag-tracking/id1644524830"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={t("home.app.appstore")}
              className="h-12 hvr_hover_opacity"
              alt=""
            />
          </a>
          <a
            className=" "
            href="https://play.google.com/store/apps/details?id=com.downriver.safetag"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={t("home.app.playstore")}
              className="h-12 hvr_hover_opacity"
              alt=""
            />
          </a>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-5 gap-8 w-full max-w-6xl py-12 px-5">
        <img
          src="/assets/home/logo_white.webp"
          className="w-20 object-contain mx-auto lg:ml-0 hidden md:block"
          alt=""
          loading="lazy"
        />
        <div className="flex flex-col items-start text-sm text-white gap-2 font-light">
          <p className="font-bold">{t("footer.shop.title")}</p>
          <Link to="/wired-trackers">{t("footer.shop.wired")}</Link>
          <Link to="/wireless-trackers">{t("footer.shop.wireless")}</Link>
          <a
            href="https://www.amazon.co.uk/safetag"
            target="_blank"
            rel="noopener noreferrer"
          >
            Amazon
          </a>
          <a
            href="https://www.ebay.co.uk/sch/safetagdirect/m.html?item=314181167467&rt=nc&_trksid=p2047675.m3561.l2562"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ebay
          </a>
        </div>
        <div className="flex flex-col items-start text-sm text-white gap-2 font-light">
          <h6 className="font-bold">{t("footer.help.title")}</h6>
          <Link to="/support">{t("footer.help.faqs")}</Link>
          <Link to="/privacy">{t("footer.help.privacy")}</Link>
          <Link to="/tos">{t("footer.help.terms")}</Link>
        </div>
        <div className="flex flex-col items-start text-sm text-white gap-2 font-light">
          <h6 className="font-bold">{t("footer.help.contact")}</h6>
          <Link to="/contact">{t("footer.help.contact")}</Link>
          <Link to="/contact?reason=business" className="text-sm">
            {t("footer.contact.sales")}
          </Link>
        </div>
        <div className="flex flex-col text-sm items-start text-white gap-2 font-light">
          <h6 className="font-bold">{t("footer.connect.title")}</h6>

          <Link to="/about">{t("footer.connect.about")}</Link>

          <div className="flex items-center gap-6 pb-3 pt-2">
            <a
              href="https://www.instagram.com/safetagtracking"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillInstagram className="text-white w-6 h-6 cursor-pointer hvr_hover_opacity" />
            </a>
            <a
              href="https://www.facebook.com/safetagtracking"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsFacebook className="text-white w-5 h-5 cursor-pointer hvr_hover_opacity" />
            </a>
            <a
              href="https://www.youtube.com/channel/UC4tacBxq94tcUVTG8ZTZoBQ/featured"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsYoutube className="text-white w-6 h-6 cursor-pointer hvr_hover_opacity" />
            </a>
            <a
              href="https://www.linkedin.com/company/safetag-tracking"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsLinkedin className="text-white w-5 h-5 cursor-pointer hvr_hover_opacity" />
            </a>
          </div>

          <Link to="/referrals">{t("footer.connect.referral")}</Link>
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row items-center justify-start md:pb-20 py-8 gap-4 md:gap-12 w-full max-w-6xl">
        <div className="flex flex-col gap-2">
          <p className="text-sm text-white text-center md:text-left">
            {t("footer.copyright.text")}
          </p>

          <p className="text-xs text-white text-center md:hidden opacity-60">
            {t("footer.address.line1")}
            <br />
            <span className="md:hidden">{"\n"}</span>
            <span className="hidden md:inline"> • </span>
            {t("footer.address.line2")}
          </p>
          <p className="text-xs text-white text-left hidden md:block opacity-60">
            {t("footer.address.line1")} • {t("footer.address.line2")}
          </p>
        </div>
        <div className="flex items-center gap-12">
          <div className="font-semibold relative">
            <div
              className={
                currShow
                  ? ` w-32 bg-white border shadow-md animateClass  absolute  -left-9 z-20 rounded-xl`
                  : ` hidden`
              }
            >
              <div className=" pt-2 flex items-center justify-end pr-1 -mb-2">
                <GrFormClose
                  onClick={() => setCurrShow(false)}
                  className="w-5 h-5 cursor-pointer "
                />
              </div>
              <div className=" p-3">
                {currencyArray.map((currency, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      setSelectedCur(currency);
                      setCurrShow(false);
                      updateCurrency(currency.code);
                    }}
                    className={` flex  items-center justify-center hvr_border2 cursor-pointer gap-2 px-2 py-2 ${
                      selectedCur.code === currency.code &&
                      "border border-black"
                    } rounded-md `}
                  >
                    {currency.symbol} {currency.code}
                  </div>
                ))}
              </div>
            </div>
            <p
              className=" cursor-pointer flex items- text-white"
              onClick={() => {
                setLangShow(false);
                setCurrShow(!currShow);
              }}
            >
              {selectedCur.symbol} {selectedCur.code}
              {currShow ? <RiArrowDownSFill /> : <RiArrowUpSFill />}
            </p>
          </div>

          <div className="flex items-center gap-1 cursor-pointer font-semibold relative">
            <div
              className={
                langShow
                  ? `  w-32 bg-white shadow-md border animateClass2  absolute  -left-4 z-20 rounded-xl`
                  : ` hidden`
              }
            >
              <div className="flex pt-2 items-center justify-end pr-1 -mb-2">
                <GrFormClose
                  onClick={() => setLangShow(false)}
                  className="w-5 h-5 cursor-pointer "
                />
              </div>
              <div className=" px-3 pb-2">
                {languageArray.map((language, i) => (
                  <div
                    key={i}
                    onClick={() => {
                      setSelectedLang(language.name);
                      setLangShow(false);
                      updateLanguage(language.code);
                    }}
                    className={` flex items-center justify-center hvr_border2  mt-1 cursor-pointer gap-2 px-2 py-2 ${
                      selectedLang === language.name && "border"
                    } rounded-md `}
                  >
                    {language.name}
                  </div>
                ))}
              </div>
            </div>

            <p
              className=" cursor-pointer flex items-center gap-1 text-white"
              onClick={() => {
                setLangShow(!langShow);
                setCurrShow(false);
              }}
            >
              <FaGlobeAfrica className="w-5 h-5 " />
              {selectedLang}

              {langShow ? <RiArrowDownSFill /> : <RiArrowUpSFill />}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
